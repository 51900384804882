import React, { useEffect } from 'react';
import WhyExamCard from '../WhyExamCard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPastPapers } from '../../actions/examAction';
const WhyExamanytime = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language).selectedLanguage;
  const pastPapers = useSelector((state) => state.exam.pastPapers);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  useEffect(() => {
    dispatch(fetchPastPapers());
  }, []);
  return (
    <section className="container-fluid  ptb-100 position-relative why-exam-section">
      <div className="max-container ">
        <div className="row" data-aos="zoom-in-up">
          <div className="col-md-12 text-center">
            <h2 className="mb-4">{t('why_use_exam_anytime')}</h2>
            <p className="mx-p-width m-auto">{t('why_use_examAnytime_description')}</p>
          </div>
        </div>
        <div className="row mt-5 pt-100">
          {pastPapers.map((pastPapers, index) => (
            <div className="col-lg-4" data-aos="zoom-in-up" key={index}>
              <WhyExamCard pastPapers={pastPapers} />
            </div>
          ))}

          {/* <div className="col-lg-4" data-aos="zoom-in-up">
            <WhyExamCard />
          </div>
          <div className="col-lg-4" data-aos="zoom-in-up">
            <WhyExamCard />
          </div> */}
        </div>
      </div>
      <span className="why-exam-pattern">
        <svg
          width="1920"
          height="1366"
          viewBox="0 0 1920 1366"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="216" cy="475" r="475" fill="#7E7EFF" fillOpacity="0.1" />
          <circle cx="1678" cy="491" r="324" fill="#F9D21E" fillOpacity="0.1" />
          <circle cx="1005" cy="897" r="192" fill="#FF5EB4" fillOpacity="0.1" />
          <ellipse cx="1485.5" cy="853" rx="516.5" ry="513" fill="#FF5EB4" fillOpacity="0.1" />
          <ellipse cx="382.5" cy="853" rx="516.5" ry="513" fill="#5EFFD8" fillOpacity="0.1" />
        </svg>
      </span>
    </section>
  );
};

export default WhyExamanytime;
