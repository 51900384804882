import React from 'react';
import MainLayout from '../../layouts/MainLayout';

function sitemap() {
  return (
    <MainLayout>
      <h1>Sitemap</h1>
      <div>
        <img src="https://api.examanytime.com/api/file/images/9081b867-1bbe-46e8-8bbc-2f3bbd3d38d3.png"></img>
      </div>
    </MainLayout>
  );
}

export default sitemap;
