import React, { useEffect } from 'react';
import BgImg from '../../assets/images/chemistry-bg.png';
import Link from '@material-ui/core/Link';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import ReadOnly from '../RichTextEditor/ReadOnly';
import { Box } from '@material-ui/core';

const getButtonText = (status) => {
  switch (status) {
    case 'PENDING':
      return 'Start_Exam';
    case 'STARTED':
    case 'REVIEW':
      return 'Continue_Exam';
    case 'CORRECTED':
      return 'View_result';
    case 'COMPLETED':
      return 'View_Answers';
    case 'PENDING_CORRECTION':
      return 'View_Answers';
    default:
      return null;
  }
};

const getUriPath = (exam) => {
  switch (exam.status) {
    case 'CORRECTED':
      return `/completed-exam-results/${exam.examId}/${exam.attemptId}`;
    case 'COMPLETED':
      return `/completed-exam-results/${exam.examId}/${exam.attemptId}`;
    case 'PENDING_CORRECTION':
      return `/completed-exam-results/${exam.examId}/${exam.attemptId}`;
    default:
      localStorage.clear('examDuration', 'componentMounted', 'examStartTime');
      localStorage.setItem('NavName', 'MyLearning');
      return `/exam/${exam.examId}/${exam.attemptId}`;
  }
};
const getDescription = (desc) => {
  let parsedDescription;
  let isError = false;

  try {
    parsedDescription = JSON.parse(desc);
  } catch (error) {
    isError = true;
    return desc;
  }

  if (!isError && typeof parsedDescription == 'number') {
    return parsedDescription;
  } else {
    let readonly = <ReadOnly initialValue={parsedDescription} />;
    return readonly;
  }
};

export default function SubScriptionResults(props) {
  const { examList, title } = props;
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);
  return (
    <div className="container-fluid px-0 mb-5">
      <p className="py-4">
        {t('my_learning_desc1')}
        {examList.length} {t(title)} {t('my_learning_desc2')}
      </p>
      <div className="row">
        {examList.map((exam, index) => (
          <div className="col-lg-4" key={index}>
            <div className="ex-card in--my-learning">
              <div className="ex-card-img">
                <img className="img-bg" src={BgImg} />
              </div>
              <a>
                <h3 className="ex-title">{exam.title}</h3>
              </a>
              <div className="ex-tag-block d-flex pb-4">
                <span className="tag-item mr-3 fw-800" style={{ backgroundColor: '#E2F6FF' }}>
                  {exam.category}
                </span>
                <span className="tag-item mr-3 fw-800" style={{ backgroundColor: '#E2FFE3' }}>
                  {exam.grade}
                </span>
              </div>
              <p className="ex-info mb-4">{getDescription(exam.description)}</p>
              <div className="author-block d-flex align-items-center">
                <img className="author-img mr-2" src={BgImg} />
                <span className="author-name fw-600">{exam.teacherName}</span>
              </div>
              <div className="btm-ex-block d-flex mt-4">
                {(() => {
                  const specificDateTime = exam.scheduledTime;
                  const current = new Date();
                  const specificDate = new Date(Date.parse(specificDateTime));
                  const formattedDate = format(new Date(specificDate), 'dd/MM/yyyy HH:mm:ss');
                  let timeToAdd = exam.delayStartInMin * 60 * 1000;
                  let newTimestamp = specificDate.getTime() + timeToAdd;
                  let newDateTime = new Date(newTimestamp);
                  var bool = exam.scheduled;
                  if (bool) {
                    if (specificDate <= current && current <= newDateTime) {
                      return (
                        <div>
                          <Link href={getUriPath(exam)}>{t(`${getButtonText(exam.status)}`)}</Link>
                        </div>
                      );
                    } else if (specificDate >= current) {
                      return (
                        <div>
                          <p>This Exam will be Scheduled to on {formattedDate}</p>
                        </div>
                      );
                    } else if (newDateTime <= current) {
                      return (
                        <div>
                          <p>This Exam was Finished on {formattedDate}</p>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <Link
                        className="text-white btn btn-success px-5 fw-800"
                        href={getUriPath(exam)}
                      >
                        {t(`${getButtonText(exam.status)}`)}
                      </Link>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Box
        sx={{
          width: '100%',
          height: 100
        }}
      />
    </div>
  );
}
