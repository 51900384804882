import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import '../../assets/css/theme-style.css';
import ReadOnly from '../RichTextEditor/ReadOnly';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0
  },
  numList: {
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    borderBlock: 2
  },
  checkBox: {
    marginBottom: 0,
    marginRight: 0
  },
  qnumber: {
    marginLeft: 10,
    marginRight: 10
  }
}));

export default function AnswerList(props) {
  const { answers, handleChange, selectedAnswer, chaneColor } = props;
  const classes = useStyles();
  return (
    <List dense className={classes.root}>
      {answers.map((answer, i) => {
        const labelId = `checkbox-list-secondary-label-${answer.id}`;
        const handler = () => {
          // selectedAnswer.pop();
          // selectedAnswer.push(i + 1);
          handleChange(i + 1, answers);
        };
        return (
          <div
            key={answer.id}
            onClick={handler}
            style={{ backgroundColor: chaneColor ? '#D4F1F4' : '' }}
          >
            <ListItem
              style={{
                backgroundColor: selectedAnswer[0] - 1 == answer.id ? 'gray' : '' //isChecked &&
              }}
            >
              <label className={classes.qnumber}>{i + 1}.</label>
              <ListItemText id={labelId}>
                <ReadOnly initialValue={answer.initialValue} />
              </ListItemText>
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  checked={selectedAnswer.indexOf(i + 1) !== -1}
                  inputProps={{ 'aria-labelledby': labelId }}
                  className={classes.checkBox}
                  id="mycheckbox"
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        );
      })}
    </List>
  );
}
