import React, { Component, Fragment } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Container } from '@material-ui/core';
import Profile from '../../components/Profile/Profile';
import { fetchProfileDetail } from '../../actions/userAction';
import { connect } from 'react-redux';
import { patchProfileDetail } from '../../actions/userAction';
import { uploadFiles } from '../../actions/examPaperAction';
import Compressor from 'compressorjs';
import { updatePassword } from '../../actions/userAction';
import { getBalancePoints, promoCodeTopup } from '../../actions/pointsAction';
import UserDelete from '../../components/Modal/UserDelete';
import { deleteUserDetails } from '../../actions/userAction';

const USER = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  profileImage: ''
};

export class index extends Component {
  constructor() {
    super();
    this.state = {
      user: USER,
      errors: {},
      submitting: false,
      passwordUpdateBtn: false,
      passwordUpdateMsg: '',
      availableBalance: '',
      promoCode: '',
      promoCodeError: '',
      open: false,
      massage: 'Please Make sure,Once you delete your all details will be delete'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
    this.handleChangePromoCode = this.handleChangePromoCode.bind(this);
    this.handleClickPromoCode = this.handleClickPromoCode.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
  }
  componentDidMount() {
    const { fetchProfileDetail, getBalancePoints } = this.props;
    fetchProfileDetail({});
    getBalancePoints();
  }
  componentDidUpdate(preProps) {
    const { profile, availaPoints } = this.props;
    if (preProps.profile != profile) {
      this.setState({ user: profile });
    }
    if (preProps.availaPoints != availaPoints) {
      this.setState({ availableBalance: availaPoints.pointsBalance });
    }
  }
  handleUploadClick = (e) => {
    const { uploadFiles } = this.props;
    const { errors } = this.state;
    this.setState({ profileImage: 'url' });
    const name = e.target.name;
    errors[name] = '';
    const file = e.target.files[0];

    if (!file) {
      errors[name] = 'Please select an image';
      this.setState({ errors });
      return;
    }
    new Compressor(file, {
      quality: 0.8,
      success(result) {
        const formData = new FormData();
        formData.append('file', result);
        uploadFiles(formData, (url) => {
          this.setState({ profileImage: url });
        });
      },
      error(err) {
        console.error(err.message);
      }
    });
  };
  handleChange = (e) => {
    const { user, errors } = this.state;
    const { name, value } = e.target;
    user[name] = value;
    errors[name] = '';
    this.setState({ user, errors });
  };
  handleUpdate = () => {
    const { user } = this.state;
    const { patchProfileDetail } = this.props;
    patchProfileDetail(user);
  };
  handlePasswordUpdate = () => {
    const { updatePassword } = this.props;
    updatePassword(() => {
      this.setState({
        passwordUpdateMsg:
          'An email is sent with instructions to update the password,Please Check your Email to Update Password',
        passwordUpdateBtn: true
      });
    });
  };
  handleChangePromoCode(e) {
    const { value } = e.target;
    this.setState({ promoCode: value });
  }
  handleClickPromoCode() {
    const { promoCodeTopup } = this.props;
    const { promoCode } = this.state;
    promoCodeTopup(
      promoCode,
      (val) => {
        this.setState({ promoCodeError: val });
      },
      () => getBalancePoints()
    );
  }
  handleDelete() {
    const { deleteUserDetails } = this.props;
    deleteUserDetails(() => window.location.reload());
  }
  handleClickDelete() {
    this.setState({ open: true });
  }
  handleClose() {
    this.setState({ open: false });
  }
  render() {
    const { open, massage } = this.state;
    return (
      <Fragment>
        <MainLayout>
          <Container className="main-container">
            <Profile
              {...this.state}
              handleChange={this.handleChange}
              handleUpdate={this.handleUpdate}
              handleClickDelete={this.handleClickDelete}
              handleUploadClick={this.handleUploadClick}
              handlePasswordUpdate={this.handlePasswordUpdate}
              handleChangePromoCode={this.handleChangePromoCode}
              handleClickPromoCode={this.handleClickPromoCode}
            />
            <UserDelete
              open={open}
              handleClose={this.handleClose}
              handleSubmit={this.handleDelete}
              msg={massage}
            />
          </Container>
        </MainLayout>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  profile: state.user.userProfile,
  availaPoints: state.points.availableBalance
});
const mapDispatchToProps = {
  fetchProfileDetail,
  patchProfileDetail,
  uploadFiles,
  updatePassword,
  getBalancePoints,
  promoCodeTopup,
  deleteUserDetails
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
