import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import transalationEN from '../assets/locales/en.locale.json';
import transalationTamil from '../assets/locales/tamil.locale.json';
import transalationSinhala from '../assets/locales/sinhala.locale.json';

const resources = {
  en: {
    translation: transalationEN
  },
  tamil: {
    translation: transalationTamil
  },
  sinhala: {
    translation: transalationSinhala
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
