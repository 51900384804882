import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Card, CardContent, Grid } from '@material-ui/core';
import GoldCoin from '../../assets/images/goldcoin.png';
import { Button } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExamPaperById } from '../../actions/examPaperAction';
import { useTranslation } from 'react-i18next';
import {
  getBalancePoints,
  sendOTPforPointsTopup,
  verifyOTPwithOTPId
} from '../../actions/pointsAction';
import { Spinner } from 'react-bootstrap';
import { Box, Typography } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function PointBasePayment() {
  const params = useParams('examPaperId');
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState({
    status: '',
    message: ''
  });
  const [otpId, setOtpId] = useState('');
  const [emailMsg, setEmailMsg] = useState('');
  const exam = useSelector((state) => state.examPaper.examPaperDetails);
  const points = useSelector((state) => state.points.availableBalance);
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language).selectedLanguage;
  const { t, i18n } = useTranslation();
  const [countdown, setCountdown] = useState(5);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(fetchExamPaperById(params.examPaperId));
    dispatch(getBalancePoints());
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const [otp, setOtp] = useState('');

  const handleSendEmail = () => {
    setLoading(true);

    if (exam.sellingPrice > points || exam.sellingPrice == null) {
      setError({
        status: 'error',
        message: 'amount_not_enough'
      });
      setLoading(false);
    } else {
      dispatch(
        sendOTPforPointsTopup(
          params.examPaperId,
          (res) => {
            if (res.status === 'success') {
              setOtpId(res.message);
              setEmailMsg('OTP sent to your email. Please check.');
              setTimeout(() => {
                setEmailMsg('');
              }, 3000);
            } else {
              setError({ status: 'error', message: res.message });
            }
            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    }
  };

  const onOTPVerify = () => {
    setLoading(true);
    dispatch(
      verifyOTPwithOTPId(otpId, otp, (res) => {
        if (res === 'Exam Subscription Failed,Please make sure your otp number is correct') {
          setError({
            status: 'error',
            message: 'exam_subscription_failed'
          });
          setLoading(false);
        } else if (
          res ===
          'The chosen payment method only supports past paper purchases. Please try again with a valid payment method.'
        ) {
          setError({
            status: 'error',
            message: 'exam_subscription_failed_pastpaper'
          });
          setLoading(false);
        } else if (res === 'Exam Subscription Successfully') {
          setError({
            status: 'success',
            message: 'exam_subscription'
          });
          setLoading(false);
          setSuccess(true);
          const interval = setInterval(() => {
            setCountdown((prev) => {
              if (prev <= 1) {
                clearInterval(interval);
                history.push('/my-learning/pending-exampage');
              }
              return prev - 1;
            });
          }, 1000);
        }
      })
    );
  };

  return (
    <MainLayout index="1">
      <Card style={{ marginLeft: '3%', marginRight: '3%' }}>
        <CardContent>
          <Grid>
            <div className="d-flex align-items-center justify-content-center">
              <div className="w-60 d-flex flex-column gap-5 rounded-lg p-4">
                <div className="text-center font-weight-medium text-white fs-3 mb-6 .col-xs-3">
                  <img src={GoldCoin} style={{ width: '50%' }} />
                  <div>
                    <h1>{t('welcome_points_base')}</h1>
                    <h1>{t('payment_method')}</h1>
                  </div>
                  {otpId != '' && <p style={{ color: 'green' }}>{emailMsg}</p>}
                  {error.status === 'error' && <p style={{ color: 'red' }}>{t(error.message)}</p>}
                  {otpId == '' ? (
                    <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4 ">
                      <div className="mt-1 d-flex justify-content-center align-items-center">
                        <Button
                          onClick={handleSendEmail}
                          className="d-flex flex-row align-items-center justify-content-center text-black py-2.5 rounded p-2"
                          style={{
                            width: '300px',
                            backgroundColor: '#17a2b8',
                            border: '#17a2b8'
                          }}
                        >
                          {loading ? <Spinner animation="border" size="sm" /> : t('send_otp')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4">
                      <label
                        className="font-weight-bold text-xl text-center mt-1"
                        style={{ color: 'black' }}
                      >
                        {t('enter_otp')}
                      </label>
                      <div className="d-flex justify-content-center align-items-center">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          numInputs={6}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{
                                width: '40px',
                                height: '40px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                textAlign: 'center',
                                margin: '2px',
                                padding: '5px'
                              }}
                            />
                          )}
                          containerStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <Button
                          onClick={onOTPVerify}
                          className="d-flex flex-row align-items-center justify-content-center py-2.5 text-black rounded p-2"
                          style={{ width: '300px', backgroundColor: '#17a2b8', border: '#17a2b8' }}
                          disabled={otp.length < 6}
                        >
                          {loading ? <Spinner animation="border" size="sm" /> : t('verify_otp')}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
      {success && (
        <Box
          sx={{
            position: 'fixed',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1300,
            bgcolor: 'rgba(0, 0, 0, 0.5)'
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              bgcolor: 'background.paper',
              p: 4,
              borderRadius: 2,
              boxShadow: 3
            }}
          >
            <Typography variant="h6" gutterBottom>
              {t('exam_subscription')}
            </Typography>
            <Typography gutterBottom>Redirect in...</Typography>
            <div style={{ width: 75, height: 75, margin: 'auto' }}>
              <CircularProgressbar
                value={(countdown / 5) * 100}
                text={`${countdown}s`}
                styles={buildStyles({
                  textSize: '20px',
                  pathColor: '#17a2b8',
                  textColor: '#000',
                  trailColor: '#d6d6d6'
                })}
              />
            </div>
          </Box>
        </Box>
      )}
    </MainLayout>
  );
}

export default PointBasePayment;
