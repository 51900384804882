import {
  FETCH_EXAMS_PAPERS,
  FETCH_EXAM_PAPER_BY_ID,
  FETCH_EXAM_QUESTION_BY_ID,
  FETCH_ALL_TEACHERS,
  FETCH_TEACHER_EXAM_PAPERS,
  FETCH_ALL_QUESTIONS
} from '../utils/type';

const initialState = {
  examPapers: {
    content: []
  },
  examPaperDetails: {},
  questionDetails: {},
  questions: [],
  teachers: [],
  teacherExamPapers: []
};

const examPaperReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXAMS_PAPERS:
      return {
        ...state,
        examPapers: action.data
      };
    case FETCH_EXAM_PAPER_BY_ID:
      return {
        ...state,
        examPaperDetails: action.data
      };
    case FETCH_EXAM_QUESTION_BY_ID:
      return {
        ...state,
        questionDetails: action.data
      };
    case FETCH_ALL_TEACHERS:
      return {
        ...state,
        teachers: action.data
      };
    case FETCH_TEACHER_EXAM_PAPERS:
      return {
        ...state,
        teacherExamPapers: action.data
      };
    case FETCH_ALL_QUESTIONS:
      return {
        ...state,
        questions: action.data
      };
    default:
      return state;
  }
};

export default examPaperReducer;
