import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const JoinCommunity = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <section className="join-community position-relative z-index-0">
      <div className="container-fluid  max-container d-flex align-items-center flex-column justify-content-center vh-80">
        <div className="row" data-aos="zoom-in-up">
          <div className="col-md-12 text-center">
            <h2 className="text-white">{t('join_community')}</h2>
            <p className="text-white">{t('join_community_description')}</p>
          </div>
        </div>

        <div className="row mt-5 mb-5" data-aos="fade-in">
          <div className="col-lg-12 text-center" data-aos="zoom-in-up">
            <button
              className="btn btn-primary py-2 px-4 mt-5"
              onClick={() => history.push('/community')}
            >
              <span className="mr-3">{t('get_start_today')}</span>
              <span>
                <svg
                  width="46"
                  height="46"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="23" cy="23" r="23" fill="#fff" />
                  <path
                    d="M16.4286 23H29.5714M29.5714 23L24.6429 18.0714M29.5714 23L24.6429 27.9286"
                    stroke="#00394f"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <span className="jn-com-pattern">
        <svg
          width="1920"
          height="903"
          viewBox="0 0 1920 903"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.19">
            <path
              d="M1883.5 527.5C1883.5 678.89 1775.99 801.5 1643.5 801.5C1511.01 801.5 1403.5 678.89 1403.5 527.5C1403.5 376.11 1511.01 253.5 1643.5 253.5C1775.99 253.5 1883.5 376.11 1883.5 527.5Z"
              stroke="#EBEBEB"
            />
            <path
              d="M1991.5 274.5C1991.5 425.89 1883.99 548.5 1751.5 548.5C1619.01 548.5 1511.5 425.89 1511.5 274.5C1511.5 123.11 1619.01 0.5 1751.5 0.5C1883.99 0.5 1991.5 123.11 1991.5 274.5Z"
              stroke="#EBEBEB"
            />
            <path
              d="M1599.5 175.5C1599.5 250.124 1546.61 310.5 1481.5 310.5C1416.39 310.5 1363.5 250.124 1363.5 175.5C1363.5 100.876 1416.39 40.5 1481.5 40.5C1546.61 40.5 1599.5 100.876 1599.5 175.5Z"
              stroke="#EBEBEB"
            />
            <path
              d="M427.5 274.5C427.5 425.89 319.988 548.5 187.5 548.5C55.0117 548.5 -52.5 425.89 -52.5 274.5C-52.5 123.11 55.0117 0.5 187.5 0.5C319.988 0.5 427.5 123.11 427.5 274.5Z"
              stroke="#EBEBEB"
            />
            <path
              d="M561.5 628.5C561.5 779.89 453.988 902.5 321.5 902.5C189.012 902.5 81.5 779.89 81.5 628.5C81.5 477.11 189.012 354.5 321.5 354.5C453.988 354.5 561.5 477.11 561.5 628.5Z"
              stroke="#EBEBEB"
            />
            <path
              d="M515.5 323.5C515.5 398.124 462.609 458.5 397.5 458.5C332.391 458.5 279.5 398.124 279.5 323.5C279.5 248.876 332.391 188.5 397.5 188.5C462.609 188.5 515.5 248.876 515.5 323.5Z"
              stroke="#EBEBEB"
            />
          </g>
        </svg>
      </span>
    </section>
  );
};

export default JoinCommunity;
