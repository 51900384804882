import axios from 'axios';
import { getValidToken } from './filterAction';
import { API_URL, authHeader } from '../utils/helper';
import { FETCH_BALANCE_POINT } from '../utils/type';

export const getBalancePoints = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/points/get-points`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_BALANCE_POINT,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is an error when you getting points', e);
      });
  };
};
export const promoCodeTopup = (promoCode, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/points/promo-points-topup?promoCode=${promoCode}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((error) => {
        console.error('error when you topup promocode', error);
      });
  };
};
export const sendOTPforPointsTopup = (examId, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/points/send-otp?examId=${examId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess({ status: 'success', message: res.data });
      })
      .catch((e) => {
        onSuccess({ status: 'error', message: e.response.data });
        console.log('there is a error when you requesting OTP', e);
      });
  };
};
export const verifyOTPwithOTPId = (otpId, otp, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/points/sent-otp-verification?otpId=${otpId}&otp=${otp}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        onSuccess('error');
        console.error('when you enter the mailed OTP', e);
      });
  };
};
