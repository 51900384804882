import { Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Comments from './Comments';
import ReadOnly from '../../components/RichTextEditor/ReadOnly';
import keycloakService from '../../keycloakService';
import MainLayout from '../../layouts/MainLayout';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch } from 'react-redux';
import { getArticlesById } from '../../actions/articleAction';

function Articles() {
  const [selectedArticle, setSelectedArticle] = useState('');
  const isLoggedIn = keycloakService.isLoggedIn();
  const articleId = useParams('articleId').articleId;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getArticlesById(articleId, (res) => setSelectedArticle(res)));
  }, []);
  return (
    <MainLayout>
      {selectedArticle && (
        <Card>
          <CardContent>
            <ReadOnly initialValue={JSON.parse(selectedArticle.article)} />
          </CardContent>
          {isLoggedIn && <Comments articleId={selectedArticle.id} />}
        </Card>
      )}
    </MainLayout>
  );
}

export default Articles;
