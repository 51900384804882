import axios from 'axios';
// import { getValidToken } from './filterAction';
import { API_URL, authHeader } from '../utils/helper';
import { getValidToken } from './filterAction';

export const getArticles = (onSuccess = () => {}) => {
  return async () => {
    axios
      .get(`${API_URL}/article/get-article`)
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('There is an error in fetching articles', e);
      });
  };
};
export const getArticlesById = (articleId, onSuccess = () => {}) => {
  return async () => {
    axios
      .get(`${API_URL}/article/get-article-byId?articleId=${articleId}`)
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('There is an error in fetching articles', e);
      });
  };
};

export const getComments = (articleId, onSuccess = () => {}) => {
  return async () => {
    axios
      .get(`${API_URL}/article/get-comment?articleId=${articleId}`)
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('There is an error in fetching comments', e);
      });
  };
};

export const addComments = (comments, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/article/add-comment`, comments, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('There is an error in fetching comments', e);
      });
  };
};
