import React, { Component } from 'react';
import axios from 'axios';
import { API_URL, authHeader } from '../../utils/helper';
import keycloakService from '../../keycloakService';
import MainLayout from '../../layouts/MainLayout';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import { CardActionArea, Grid } from '@material-ui/core';
import { Payment } from '@material-ui/icons';
import { StayCurrentPortrait } from '@material-ui/icons';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import BitCoin from '../../assets/images/bitcoin.png';
import FreeCredit from '../../assets/images/freecoin.png';
import { getBalancePoints } from '../../actions/pointsAction';
import Progress from '../../components/Loader/Progress';
import toast, { Toaster } from 'react-hot-toast';
import { Box, Typography } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class BuyExam extends Component {
  constructor() {
    super();
    this.state = {
      paymentLink: null,
      title: '',
      examPaperId: '',
      local: '',
      availableBalance: '',
      availableFreeBalance: '',
      pastPaper: false,
      isDataLoading: true,
      success: false,
      countdown: 5
    };
    this.handleLocalization = this.handleLocalization.bind(this);
    this.handleCreditCard = this.handleCreditCard.bind(this);
    this.handleMobilePay = this.handleMobilePay.bind(this);
    this.handlePointBasePay = this.handlePointBasePay.bind(this);
    this.handleFreeCreditPay = this.handleFreeCreditPay.bind(this);
  }

  async componentDidMount() {
    const { selectedLanguage, getBalancePoints, match } = this.props;
    const { examPaperId } = match.params;
    const params = new URLSearchParams(window.location.search);
    const state = params.get('state');
    if (state === 'CONFIRMED') {
      this.setState({ success: true });
      const interval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.countdown <= 1) {
            clearInterval(interval);
            window.location.href = `${window.location.origin}/my-learning/pending-exampage`;
          }
          return { countdown: prevState.countdown - 1 };
        });
      }, 1000);
    }

    const token = await this.getToken();

    axios
      .get(`${API_URL}/exam/paper/${examPaperId}`, authHeader(token))
      .then((res) => {
        this.setState({ pastPaper: res.data.pastPaper });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });

    this.setState({ local: selectedLanguage, examPaperId });
    getBalancePoints();
  }

  componentDidUpdate(preProps) {
    const { selectedLanguage, availaPoints } = this.props;
    if (selectedLanguage !== preProps.selectedLanguage) {
      this.setState({ local: selectedLanguage });
    }
    if (preProps.availaPoints != availaPoints) {
      this.setState({
        availableBalance: availaPoints.pointsBalance,
        availableFreeBalance: availaPoints.freeCreditBalance,
        isDataLoading: false
      });
    }
  }
  getToken = async () => {
    const isLoggedIn = keycloakService.isLoggedIn();
    if (isLoggedIn) {
      await keycloakService.updateToken();
      const token = keycloakService.getToken();
      return token;
    }
    return keycloakService.login();
  };
  handleLocalization(heading) {
    const { local } = this.state;
    if (heading == 'payments') {
      if (local == 'sinhala') {
        return 'ගෙවීම';
      } else if (local == 'tamil') {
        return 'கொடுப்பனவுகள்';
      } else {
        return 'Payments';
      }
    }
    if (heading == 'selectPayment') {
      if (local == 'sinhala') {
        return 'ගෙවීම් ක්රමයක් තෝරන්න';
      } else if (local == 'tamil') {
        return 'பணம் செலுத்தும் முறையைத் தேர்ந்தெடுக்கவும்';
      } else {
        return 'Select a Payment Method';
      }
    }
    if (heading == 'mobilePay') {
      if (local == 'sinhala') {
        return 'ජංගම දුරකථනයෙන් ගෙවීමට මෙතන ක්ලික් කරන්න';
      } else if (local == 'tamil') {
        return 'மொபைலில் இருந்து பணம் செலுத்த இங்கே கிளிக் செய்யவும்';
      } else {
        return 'Click here to pay from Mobile';
      }
    }
    if (heading == 'creaditDebit') {
      if (local == 'sinhala') {
        return 'ණය කාඩ්පත/ හර කාඩ්පත';
      } else if (local == 'tamil') {
        return 'கிரெடிட் / டெபிட் கார்டு';
      } else {
        return 'Credit / Debit Card';
      }
    }
    if (heading == 'pointsBasePayment') {
      if (local == 'sinhala') {
        return 'Point base payment';
      } else if (local == 'tamil') {
        return 'Point base payment';
      } else {
        return 'Point base payment';
      }
    }
    if (heading == 'freeCredit') {
      if (local == 'sinhala') {
        return 'Free Credit';
      } else if (local == 'tamil') {
        return 'Free Credit';
      } else {
        return 'Free Credit';
      }
    }
    if (heading == 'payment_link_not_receive') {
      if (local == 'sinhala') {
        return 'ගෙවීම් සබැඳියක් ලැබී නැත.';
      } else if (local == 'tamil') {
        return 'கட்டண இணைப்பு எதுவும் பெறப்படவில்லை.';
      } else {
        return 'No payment link received.';
      }
    }
    if (heading == 'exam_subscription') {
      if (local == 'sinhala') {
        return 'විභාග Subscribe කිරීම සාර්තකයි.';
      } else if (local == 'tamil') {
        return 'தேர்வுச் சந்தா வெற்றிகரமாக';
      } else {
        return 'Exam Subscription successful';
      }
    }
  }
  handleCreditCard = async () => {
    const { examPaperId } = this.state;
    try {
      ReactGA.event({
        category: 'User',
        action: 'Chosen Credit/Debit Card Payment Method'
      });
      const token = await this.getToken();
      const response = await axios.post(
        `${API_URL}/payment/pay?examId=${examPaperId}`,
        {},
        authHeader(token)
      );
      const paymentLink = response.data;
      if (paymentLink) {
        window.location.href = paymentLink;
      } else {
        toast.error(this.handleLocalization('payment_link_not_receive'));
      }
    } catch (error) {
      toast.error(error.response.data);
      console.error('Error during payment process:', error.response);
    }
  };
  handleMobilePay = () => {
    const { history } = this.props;
    const { examPaperId } = this.state;
    ReactGA.event({
      category: 'User',
      action: 'Chosen Mobile Payment Method'
    });
    history.push(`/mobile-pay/${examPaperId}`);
  };
  handlePointBasePay = () => {
    const { history } = this.props;
    const { examPaperId } = this.state;
    history.push(`/point-base-payment/${examPaperId}`);
  };
  handleFreeCreditPay = () => {
    const { history } = this.props;
    const { examPaperId } = this.state;
    history.push(`/free-credit-pay/${examPaperId}`);
  };
  render() {
    const { availableBalance, pastPaper, availableFreeBalance, isDataLoading, success, countdown } =
      this.state;
    return (
      <MainLayout index="1">
        <Toaster toastOptions={{ duration: 5000 }} />
        <div className="container-fluid px-0 px-md-5 md-5 BuyExam1 BuyExam2 BuyExam2k BuyExam4k">
          <div className="container-header-seperator"></div>
          <h3 className="ml-3" style={{ marginTop: 100, marginLetf: '10px' }}>
            {this.handleLocalization('payment')}
          </h3>
          <h6 className="ml-3">{this.handleLocalization('selectPayment')}</h6>
          <Grid container spacing={1}>
            {isDataLoading ? (
              <Grid item xs={12} sm={12}>
                <Progress isLoading={isDataLoading} />
              </Grid>
            ) : (
              <>
                {' '}
                <Grid item xs={4} sm={4}>
                  <Card key="1" style={{ margin: '5' }}>
                    <CardActionArea>
                      <Link onClick={this.handleCreditCard} underline="none">
                        <CardContent>
                          <InputLabel
                            className="pt-3"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label style={{ color: '#2E8BC0' }}>
                              {this.handleLocalization('creaditDebit')}
                            </label>
                            <Payment style={{ marginLeft: 'auto', color: '#2E8BC0' }} />
                          </InputLabel>
                        </CardContent>
                      </Link>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Card key="1" style={{ margin: '5' }}>
                    <CardActionArea>
                      <Link onClick={this.handleMobilePay}>
                        <CardContent>
                          <InputLabel
                            className="pt-3"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label style={{ color: '#2E8BC0' }}>
                              {this.handleLocalization('mobilePay')}
                            </label>
                            <StayCurrentPortrait style={{ marginLeft: 'auto', color: '#2E8BC0' }} />
                          </InputLabel>
                        </CardContent>
                      </Link>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Card key="1" style={{ margin: '5' }}>
                    <CardActionArea>
                      <Link onClick={this.handlePointBasePay} underline="none">
                        <CardContent>
                          <InputLabel
                            className="pt-3"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <label style={{ color: '#2E8BC0' }}>
                              {this.handleLocalization('pointsBasePayment')}
                            </label>
                            <h6 style={{ color: '#2E8BC0', paddingLeft: '10px' }}>
                              {availableBalance}(Points)
                            </h6>
                            <img src={BitCoin} style={{ width: '7%', marginLeft: 'auto' }} />
                          </InputLabel>
                        </CardContent>
                      </Link>
                    </CardActionArea>
                  </Card>
                </Grid>
                {pastPaper && (
                  <Grid item xs={4} sm={4}>
                    <Card key="1" style={{ margin: '5' }}>
                      <CardActionArea>
                        <Link onClick={this.handleFreeCreditPay} underline="none">
                          <CardContent>
                            <InputLabel
                              className="pt-3"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <label style={{ color: '#2E8BC0' }}>
                                {this.handleLocalization('freeCredit')}
                              </label>
                              <h6 style={{ color: '#2E8BC0', paddingLeft: '10px' }}>
                                {availableFreeBalance}(Points)
                              </h6>
                              <img src={FreeCredit} style={{ width: '7%', marginLeft: 'auto' }} />
                            </InputLabel>
                          </CardContent>
                        </Link>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          {success && (
            <Box
              sx={{
                position: 'fixed',
                inset: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1300,
                bgcolor: 'rgba(0, 0, 0, 0.5)'
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                  bgcolor: 'background.paper',
                  p: 4,
                  borderRadius: 2,
                  boxShadow: 3
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {this.handleLocalization('exam_subscription')}
                </Typography>
                <Typography gutterBottom>Redirect in...</Typography>
                <div style={{ width: 75, height: 75, margin: 'auto' }}>
                  <CircularProgressbar
                    value={(countdown / 5) * 100}
                    text={`${countdown}s`}
                    styles={buildStyles({
                      textSize: '20px',
                      pathColor: '#17a2b8',
                      textColor: '#000',
                      trailColor: '#d6d6d6'
                    })}
                  />
                </div>
              </Box>
            </Box>
          )}
        </div>
      </MainLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedLanguage: state.language.selectedLanguage,
  availaPoints: state.points.availableBalance,
  availaFreePoints: state.points.freeCreditBalance
});
const mapDispatchToProps = {
  getBalancePoints
};
export default connect(mapStateToProps, mapDispatchToProps)(BuyExam);
