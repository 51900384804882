import { Grid, Button, Box, Link, CardContent, Card } from '@material-ui/core';
import React, { useEffect } from 'react';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useRef } from 'react';
import { useState } from 'react';
import Compressor from 'compressorjs';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFiles } from '../../actions/examPaperAction';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1)
//     },
//     containers: {
//       maxWidth: 600,
//       alignItems: 'center',
//       alignContent: 'center',
//       justifyContent: 'center'
//     },
//     label: {
//       display: 'flex',
//       alignItems: 'center',
//       marginTop: 10,
//       marginBottom: 10,
//       alignContent: 'center',
//       justifyContent: 'end'
//     }
//   }
// }));
export default function Profile(props) {
  const {
    user,
    errors,
    submitting,
    handleChange,
    handleUpdate,
    handlePasswordUpdate,
    passwordUpdateBtn,
    passwordUpdateMsg,
    availableBalance,
    handleChangePromoCode,
    handleClickPromoCode,
    promoCodeError,
    handleClickDelete
  } = props;
  const [profileImage, setProfileImage] = useState('');
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language).selectedLanguage;
  const { t, i18n } = useTranslation();
  const [displaySize, setDisplay] = useState('');

  useEffect(() => {
    const handleScreenSize = () => {
      if (window.innerWidth <= 768) {
        console.log('window resized 768');
        setDisplay('block');
      } else if (window.innerWidth > 768) {
        setDisplay('flex');
      }
    };
    handleScreenSize();

    window.addEventListener('resize', handleScreenSize);

    return () => {
      window.removeEventListener('resize', handleScreenSize);
    };
  }, []);
  useEffect(() => {
    setProfileImage(user.profileImage);
  }, [user.profileImage]);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  // const classes = useStyles();
  const inputRef = useRef(null);
  const handleUploadClick = (e) => {
    const name = e.target.name;
    errors[name] = '';
    const file = e.target.files[0];

    if (!file) {
      errors[name] = 'Please select an image';
      return;
    }
    new Compressor(file, {
      quality: 0.8,
      success(result) {
        const formData = new FormData();
        formData.append('file', result);
        dispatch(
          uploadFiles(formData, (url) => {
            setProfileImage(url);
            user.profileImage = url;
          })
        );
      }
    });
  };
  console.log(displaySize);
  return (
    <div className="container mb-0 position-relative sign-up-wrapper">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {t('my_account')}
          </h2>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div style={{ backgroundColor: '#B1B1B1', padding: '25px', borderRadius: '100%' }}>
              <img
                src={profileImage}
                style={{ width: '150px', height: '150px', borderRadius: '100%' }}
              />
            </div>
            <div
              style={{
                paddingTop: '125px',
                marginLeft: '-60px',
                cursor: 'pointer'
              }}
              onClick={() => inputRef.current.click()}
            >
              <input
                type="file"
                onChange={handleUploadClick}
                name="profileImage"
                ref={(input) => (inputRef.current = input)}
                style={{ display: 'none' }}
              />
              <CameraAltIcon
                style={{
                  fontSize: 60,
                  padding: '10px',
                  backgroundColor: '#747474',
                  borderRadius: '100%'
                }}
              />
            </div>
          </div>
        </div>
        <Card className="col-lg-6 mt-3" style={{ borderRadius: '20px' }}>
          <CardContent>
            <h4>{t('profile_details')}</h4>
            <Grid container spacing={2} style={{ paddingRight: '0px' }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={handleChange}
                  error={!!errors.firstName}
                  inputProps={{
                    maxLength: 25
                  }}
                  value={user.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  onChange={handleChange}
                  error={!!errors.lastName}
                  value={user.lastName}
                  inputProps={{
                    maxLength: 25
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  error={!!errors.email}
                  value={user.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="userName"
                  label="User Name"
                  id="userName"
                  onChange={handleChange}
                  error={!!errors.userName}
                  value={user.userName}
                  inputProps={{
                    maxLength: 25
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <Button
                  onClick={handlePasswordUpdate}
                  fullWidth
                  disabled={passwordUpdateBtn}
                  variant="outlined"
                  color="primary"
                >
                  {t('update_password')}
                </Button>
                {passwordUpdateBtn == true && <p style={{ color: 'green' }}>{passwordUpdateMsg}</p>}
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  onClick={handleUpdate}
                  fullWidth
                  disabled={submitting}
                  variant="outlined"
                  color="primary"
                >
                  {t('update')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={handleClickDelete}
                  fullWidth
                  disabled={submitting}
                  variant="outlined"
                  style={{ color: 'red', borderColor: 'red' }}
                >
                  {t('delete')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className="col-lg-12 mt-5" style={{ borderRadius: '20px' }}>
          <CardContent>
            <h3 style={{ textAlign: 'center' }}>{t('points_details')}</h3>
            <Grid container spacing={2} style={{ display: displaySize }}>
              <Grid item xs={12} sm={displaySize == 'block' ? 12 : 6}>
                <Box
                  style={{
                    padding: '10px',
                    margin: '10px',
                    display: 'flex',
                    border: '1px solid black',
                    borderRadius: '10px'
                  }}
                  fullWidth
                >
                  <h6>{t('available_points')}</h6>
                  <div style={{ marginLeft: 'auto' }}>
                    <p>
                      {availableBalance}({t('points')})
                    </p>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={displaySize == 'block' ? 12 : 6}>
                <Box
                  style={{
                    padding: '10px',
                    margin: '10px',
                    display: 'flex',
                    border: '1px solid black',
                    borderRadius: '10px'
                  }}
                  fullWidth
                >
                  <Link>{t('transaction_details')}</Link>
                </Box>
              </Grid>
            </Grid>
            <h5 className="mt-1">{t('points_Topup')}</h5>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Link href={'/buy-points-mobile-pay'}>
                  <Box
                    style={{
                      padding: '10px',
                      display: 'flex',
                      border: '1px solid black',
                      borderRadius: '10px'
                    }}
                  >
                    <h6>{t('new_points_topup')}</h6>
                  </Box>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="mt-3"
              style={{ textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}
            >
              <Grid item xs={8} sm={8}>
                <TextField
                  className="promo-code"
                  name="promoCode"
                  variant="outlined"
                  fullWidth
                  id="PromoCode"
                  onChange={handleChangePromoCode}
                  label=" Enter Promo Code"
                  inputProps={{
                    maxLength: 25
                  }}
                />
              </Grid>
              {promoCodeError != '' && <p style={{ color: 'green' }}>{promoCodeError}</p>}
              <Grid item xs={4} sm={4}>
                <Button
                  style={{
                    backgroundColor: '#17a2b8',
                    border: '#17a2b8'
                  }}
                  onClick={() => handleClickPromoCode()}
                >
                  Subscribe Points
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
