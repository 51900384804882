import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFAQ } from '../../actions/faqAction';
import { useTranslation } from 'react-i18next';
import ReadOnly from '../RichTextEditor/ReadOnly';
import MainLayout from '../../layouts/MainLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
    width: '100%'
  },
  boxSpacing: {
    padding: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  ponints: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20
  },
  iconSize: {
    fontSize: 30
  }
}));
const renderNode = (node) => {
  return node.map((item, index) => {
    const arr = [item];
    switch (item.type) {
      case 'paragraph':
        return (
          <div key={index}>
            <ReadOnly initialValue={arr} />
          </div>
        );
      case 'image':
        return (
          <img
            key={index}
            src={item.src}
            alt={item.alt}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        );
      default:
        return null;
    }
  });
};
export default function Faq() {
  const dispatch = useDispatch();
  const screenWidth = window.innerWidth;
  const faqDetail = useSelector((state) => state.faq.faqDetail);
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  useEffect(() => {
    localStorage.setItem('NavName', 'Faq');
    if (language == 'sinhala') {
      dispatch(fetchFAQ('si'));
    } else if (language == 'tamil') {
      dispatch(fetchFAQ('ta'));
    } else {
      dispatch(fetchFAQ('en'));
    }
  }, [language]);
  const classes = useStyles();
  return (
    <Fragment>
      <CssBaseline />
      <MainLayout>
        <Container className="main-container" style={{ marginBottom: 25 }}>
          <div className={classes.root} style={{ paddingTop: screenWidth >= 768 ? '0px' : '30px' }}>
            <h1>{t('header_faq')}</h1>
            {faqDetail.length >= 1 &&
              faqDetail.map((faq) => (
                <Accordion key={faq.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} className={classes.boxSpacing}>
                      <Grid item xs={12}>
                        <div
                          className={classes.ponints}
                          style={{
                            display: 'block',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {renderNode(JSON.parse(faq.answer))}
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        </Container>
      </MainLayout>
    </Fragment>
  );
}
