import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ExamPaperDetails from '../../components/ExamPage/StartPage';
import ExamStartConfirm from '../../components/Modal/ExamStartConfirm';
import { fetchExamPaperById } from '../../actions/examPaperAction';
import { createExamAttempt, getExamAttemptStatus } from '../../actions/examAction';

class ExamStart extends Component {
  constructor() {
    super();
    this.state = {
      openConfirmModal: false,
      examPaperId: '',
      loading: false,
      examPageError: ''
    };
    this.handleStart = this.handleStart.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.navigateToExamPage = this.navigateToExamPage.bind(this);
  }

  componentDidMount() {
    const { match, fetchExamPaperById, getExamAttemptStatus } = this.props;
    const { examPaperId, examAttemptId } = match.params;
    fetchExamPaperById(examPaperId);
    getExamAttemptStatus(examAttemptId);
    this.setState({ examPaperId, loading: false });
  }

  componentDidUpdate(preProps) {
    const { examPaperId } = this.state;
    const { examAttemptDetails, history } = this.props;
    if (preProps.examAttemptDetails !== examAttemptDetails) {
      if (examAttemptDetails.status === 'STARTED' || examAttemptDetails.status === 'REVIEW') {
        history.push(`/exam/${examPaperId}/${examAttemptDetails.id}/questions`);
      }
      this.setState({ loading: false });
    }
  }

  handleStart() {
    this.setState({ openConfirmModal: false });
    this.navigateToExamPage();
  }

  handleClose() {
    this.setState({ openConfirmModal: false });
  }

  navigateToExamPage() {
    const { examPaperId } = this.state;
    const { history, createExamAttempt } = this.props;
    createExamAttempt(
      {
        examId: examPaperId
      },
      (data) => {
        this.setState({ openConfirmModal: false });
        history.push(`/exam/${examPaperId}/${data.id}/questions`);
      },
      () => {
        this.setState({
          openConfirmModal: false,
          examPageError: 'You are not subscribed for this exam'
        });
      }
    );
  }

  render() {
    const { examPaperDetails } = this.props;
    const { openConfirmModal, loading, examPageError } = this.state;
    return (
      <Fragment>
        <Header />
        <Container className="main-container">
          {loading ? (
            <div>Loading</div>
          ) : (
            <ExamPaperDetails
              examPaperDetails={examPaperDetails}
              handleStart={this.handleStart}
              examPageError={examPageError}
            />
          )}
        </Container>
        <ExamStartConfirm
          open={openConfirmModal}
          handleClose={this.handleClose}
          navigateToExamPage={this.navigateToExamPage}
        />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  examPaperDetails: state.examPaper.examPaperDetails,
  examAttemptDetails: state.exam.examAttemptDetails
});

const mapDispatchToProps = {
  fetchExamPaperById,
  createExamAttempt,
  getExamAttemptStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamStart);
