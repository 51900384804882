import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BgImg from '../../assets/images/chemistry-bg.png';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import ReadOnly from '../RichTextEditor/ReadOnly';
const useStyles = makeStyles(() => ({
  examPaperCard: {
    minWidth: 275,
    marginTop: 100,
    marginBottom: 15
  },
  startBtn: {
    float: 'right'
  },
  errorDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  errorText: {
    color: 'red'
  }
}));
const getDescription = (desc) => {
  let parsedDescription;
  let isError = false;

  try {
    parsedDescription = JSON.parse(desc);
  } catch (error) {
    isError = true;
    return (
      <Box p={1} m={0}>
        {desc}
      </Box>
    );
  }

  if (!isError && typeof parsedDescription == 'number') {
    return (
      <Box p={1} m={0}>
        {parsedDescription}
      </Box>
    );
  } else {
    let readonly = <ReadOnly initialValue={parsedDescription} />;
    return readonly;
  }
};
const ExamStart = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { examPaperDetails, handleStart, examPageError } = props;
  return (
    <div className="main-content-wrapper px-0 px-lg-3">
      <div className={classes.errorDiv}>
        <p className={classes.errorText}>{examPageError}</p>
      </div>
      <div className="ex-card" key={examPaperDetails.id || 0}>
        <div className="ex-card-img">
          <img className="img-bg" src={BgImg} />
        </div>
        <a>
          <h3 className="ex-title">{examPaperDetails.title || ''}</h3>
        </a>
        <div className="ex-tag-block d-flex pb-4">
          <div className="d-flex align-items-center">
            <span>{t('exam_no_of_questions')}</span>
            <span className="tag-item mr-3 fw-800 ml-2" style={{ backgroundColor: '#E2F6FF' }}>
              {examPaperDetails.noOfQuestions}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <span>{t('exam_duration')}</span>
            <span className="duration-item d-flex align-items-center ml-2 ">
              <span className="clock-ic mr-1 mt--5">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 4V8.5L11.5 10M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
                    stroke="#00394f"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>

              <span className="duration-text fw-800">{examPaperDetails.durationInMinute}</span>
            </span>
          </div>
        </div>
        <p className="ex-info mb-4">{getDescription(examPaperDetails.description)}</p>
        <div className="author-block d-flex align-items-center">
          <img className="author-img mr-2" src={BgImg} />
          <span className="author-name fw-600">{examPaperDetails.teacherName}</span>
        </div>
        <div className="btm-ex-block d-flex mt-4 align-items-center">
          <div className="info-b d-flex align-items-center">
            <span className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-info-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
              </svg>
            </span>
            <span>{t('exam_start_warning')}</span>
          </div>
          <div className="btn-ex-buy-now ml-auto">
            <button onClick={handleStart} className="btn btn-primary px-5 fw-800">
              {t('button_text_start')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamStart;
