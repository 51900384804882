import React, { useEffect } from 'react';
import HeaderImg from '../../assets/images/bnr-shape-ly.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import keycloakService from '../../keycloakService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function HomeBanner() {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  const history = useHistory();
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const getStart = () => {
    const isLoggedIn = keycloakService.isLoggedIn();
    if (isLoggedIn) {
      history.push('/exams');
    } else {
      history.push('/sign-up');
    }
  };
  return (
    <div className="container-fluid px-0 px-md-5 mb-0 hm-info-content position-relative">
      <div className="row align-items-center px-3">
        <div className="col-lg-7 text-center text-lg-left">
          <h1 className="h1" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            {t('anybody_can')}
            <span className="yh-text">
              {t('best_result')}{' '}
              <span className="pattern-btm-line">
                <svg
                  width="509"
                  height="25"
                  viewBox="0 0 509 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_631_1175)">
                    <path
                      d="M3.51093 15.2711C63.6801 8.3443 248.645 -1.93438 507.15 12.3652"
                      stroke="#FAB123"
                      strokeWidth="4"
                      strokeDasharray="146 148"
                    />
                    <path
                      d="M67 10.0991C127.169 3.1723 312.134 -7.10638 570.639 7.19323"
                      stroke="#FAB123"
                      strokeWidth="4"
                      strokeDasharray="146 148"
                    />
                    <path
                      d="M92.3021 14.0041C136.615 9.44643 271.621 3.50055 457.142 16.1786"
                      stroke="#FAB123"
                      strokeWidth="2"
                      strokeDasharray="106 108"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_631_1175">
                      <rect width="509" height="25" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </span>{' '}
            {t('now')}!
          </h1>
          <p className="mb-4 hm-info" data-aos="zoom-in-up">
            {t('banner_description')}
          </p>
          <button
            className="btn btn-primary py-2 px-4 mt-5"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            onClick={() => getStart()}
          >
            <span className="mr-3">{t('get_start_today')}</span>
            <span>
              <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="23" cy="23" r="23" fill="#FAB123" />
                <path
                  d="M16.4286 23H29.5714M29.5714 23L24.6429 18.0714M29.5714 23L24.6429 27.9286"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
        <div
          className="col-lg-5 text-center text-lg-right"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img className="img-fluid mb-1 img-hm-banner" src={HeaderImg} alt="Home Banner" />
        </div>
      </div>
      <span className="bg-pattern-1">
        <svg
          width="106"
          height="231"
          viewBox="0 0 106 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M86.5 118.5C29 160.927 -6 230.418 -2 230.5C-63.3036 230.5 -117 172.304 -117 111C-117 49.6964 -67.3036 3.33119e-05 -6 3.33119e-05C55.3036 3.33119e-05 144 76.0728 86.5 118.5Z"
            fill="#A5C6FA"
            fillOpacity="0.23"
          />
        </svg>
      </span>
      <span className="bg-pattern-2">
        <svg
          width="566"
          height="648"
          viewBox="0 0 566 648"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="324" cy="324" r="324" fill="#6a9bff" fillOpacity="0.1" />
        </svg>
      </span>
      <span className="bg-pattern-3">
        <svg
          width="951"
          height="1026"
          viewBox="0 0 951 1026"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="516.5" cy="513" rx="516.5" ry="513" fill="#84adff" fillOpacity="0.1" />
        </svg>
      </span>
    </div>
  );
}

export default HomeBanner;
