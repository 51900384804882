import { SET_TIMER_DURATION } from '../utils/type';

const initialState = {
  timeDuration: 0
};

const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMER_DURATION:
      return {
        ...state,
        timeDuration: action.data
      };
    default:
      return state;
  }
};

export default timerReducer;
