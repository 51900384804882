import { combineReducers } from 'redux';
import filterReducer from './filterReducer';
import languageReducer from './localizationReducer';
import loaderReducer from './loaderReducer';
import examPaperReducer from './examPaperReducer';
import examReducer from './examReducer';
import timerReducer from './timerReducer';
import cascadeFilterReducer from './cascadeFilterReducer';
import faqReducer from './faqReducer';
import userReducer from './userReducer';
import pointsReducer from './pointsReducer';

const rootReducer = combineReducers({
  filter: filterReducer,
  language: languageReducer,
  loader: loaderReducer,
  examPaper: examPaperReducer,
  exam: examReducer,
  timer: timerReducer,
  cascadeFilter: cascadeFilterReducer,
  faq: faqReducer,
  user: userReducer,
  points: pointsReducer
});

export default rootReducer;
