import React, { useEffect } from 'react';
import Filter from './Filter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const FilterCard = (props) => {
  const { handleFilterChange, clearFilter, selectedValues } = props;

  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <div className="d-flex filter-block flex-column ">
      <div style={{ marginLeft: '10px' }}>{t('exampage_filter_option')}</div>
      <div className="d-flex align-items-center flex-wrap">
        <div id="exam-1">
          <Filter
            filterKey="Language"
            filterName={t('exampage_filter_language')}
            index={0}
            handleFilterChange={handleFilterChange}
            selectedValues={selectedValues}
          />
        </div>
        <div id="exam-2">
          <Filter
            filterKey="Grade"
            filterName={t('exampage_filter_grade')}
            index={1}
            handleFilterChange={handleFilterChange}
            selectedValues={selectedValues}
          />
        </div>
        <div id="exam-3">
          <Filter
            filterKey="Subject"
            filterName={t('exampage_filter_subject')}
            index={2}
            handleFilterChange={handleFilterChange}
            selectedValues={selectedValues}
          />
        </div>
        <div id="exam-4">
          <Filter
            filterKey="Teacher"
            filterName={t('exampage_filter_teacher')}
            index={3}
            handleFilterChange={handleFilterChange}
            selectedValues={selectedValues}
          />
        </div>
        <button onClick={clearFilter} className="btn btn-primary px-5 fw-800 ml-5">
          {t('reset')}
        </button>
      </div>
    </div>
  );
};

export default FilterCard;
