import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import UserProfile from '../../components/Profile';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { updatePassword } from '../../actions/userAction';

class StudentProfile extends Component {
  constructor() {
    super();
    this.state = {
      updatePasswordSuccessMsg: '',
      isUpdatePassowrdBtnClicked: false
    };
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
  }

  handleUpdatePassword() {
    const { updatePassword } = this.props;
    this.setState({ isUpdatePassowrdBtnClicked: true });
    updatePassword(() => {
      this.setState({
        updatePasswordSuccessMsg:
          'please check your email and follow instructions to update password',
        isUpdatePassowrdBtnClicked: false
      });
    });
  }

  render() {
    const { isUpdatePassowrdBtnClicked, updatePasswordSuccessMsg } = this.state;
    return (
      <Fragment>
        <Header />
        <Container className="main-container">
          <UserProfile
            isUpdatePassowrdBtnClicked={isUpdatePassowrdBtnClicked}
            handleUpdatePassword={this.handleUpdatePassword}
            updatePasswordSuccessMsg={updatePasswordSuccessMsg}
          />
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  updatePassword
};

export default connect(null, mapDispatchToProps)(StudentProfile);
