import React, { useEffect } from 'react'; //useRef
import { useSelector, useDispatch } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import { useHistory } from 'react-router-dom';
import { updateAnswer } from '../../actions/examAction';
import { getExamAttemptDetails } from '../../actions/examAction';

const displayDigits = (value) => {
  const leftDigit = value >= 10 ? value.toString()[0] : '0';
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return `${leftDigit}${rightDigit}`;
};

function MyTimer({ expiryTimestamp, examAttemptId, examPaperId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      dispatch(
        updateAnswer({ action: 'SUBMIT' }, examAttemptId, () => {
          history.push(`/results/${examPaperId}/${examAttemptId}`);
        })
      );
    }
  });

  useEffect(() => {
    restart(expiryTimestamp);
  }, [expiryTimestamp]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ fontSize: '40px' }}>
        <span>{displayDigits(hours)}</span>:<span>{displayDigits(minutes)}</span>:
        <span>{displayDigits(seconds)}</span>
      </div>
    </div>
  );
}

export default function Timer(props) {
  const { examAttemptId, examPaperId } = props;
  const { examAttemptDetails } = useSelector((state) => state.exam);
  const dispatch = useDispatch();
  let temp = examAttemptDetails.remainingDurationInMilli;
  useEffect(() => {
    dispatch(getExamAttemptDetails(examAttemptId));
  }, [examAttemptDetails.remainingDurationInMilli]);
  const expiryTime = new Date();
  expiryTime.setMilliseconds(expiryTime.getMilliseconds() + temp);
  return (
    <div>
      <MyTimer
        expiryTimestamp={expiryTime}
        examAttemptId={examAttemptId}
        examPaperId={examPaperId}
      />
    </div>
  );
}
