import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Routes from './routes';
import { useCookies } from 'react-cookie';
import CookiesModel from './components/Modal/CookiesModel';
import ReactGA from 'react-ga4';

const MEASUREMENT_ID = 'G-Q62DQ20RK1';
ReactGA.initialize(MEASUREMENT_ID);
function App() {
  const [cookies] = useCookies(['CookiesModel']);
  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <div>
      <Routes />
      {!cookies.CookiesModel && <CookiesModel />}
    </div>
  );
}

export default App;
