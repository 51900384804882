import keycloakService from '../keycloakService';
import { createContext } from 'react';
export const LevelContext = createContext(0);
export const ROLE = {
  Student: 'student',
  Teacher: 'teacher'
};

export const isStudent = () => {
  return keycloakService && keycloakService.hasRole([ROLE.Student]);
};

export const isTeacher = () => {
  return keycloakService && keycloakService.hasRole([ROLE.Teacher]);
};

export const API_URL = process.env.REACT_APP_API_URL;
export const KEYCLOAK_SERVER_BASE_URL = process.env.REACT_APP_KEYCLOAK_SERVER_BASE_URL;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_SERVER_CLIENT_ID;
export const KEYCLAOK_REALM = process.env.REACT_APP_KEYCLOAK_SERVER_REALM;

export const authHeader = (token) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  if (token) {
    headers.headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export const authMutliFormHeader = (token) => {
  const headers = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  if (token) {
    headers.headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

export const appendQueryParams = (requestUrl, selectedValuesObj) => {
  const url = new URL(requestUrl);
  Object.keys(selectedValuesObj).map((key) => {
    const value = selectedValuesObj[key];
    url.searchParams.append(key, value);
  });
  return url;
};

export const findTeacher = (teachers, examPaper) => {
  let teacherObj = {};
  if (teachers && teachers.length > 0) {
    const teacherId = examPaper.teacher;
    if (teacherId) {
      teacherObj = teachers.find((teacher) => teacher.id === teacherId);
    }
  }
  return teacherObj;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password) => {
  const validString = /^(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const passwordObj = {
    isValid: true,
    message: ''
  };
  if (!password) {
    passwordObj.isValid = false;
    passwordObj.message = 'Please enter your password';
  }
  if (password.length < 8) {
    passwordObj.isValid = false;
    passwordObj.message = 'Your password should have minimum 8 characters';
  }
  if (!validString.test(password)) {
    passwordObj.isValid = false;
    passwordObj.message =
      'Your password should have atleast a special charater, upper and lower case letters';
  }
  return passwordObj;
};

export const validateUser = (userData) => {
  let isValid = true;
  const validationErrors = {};
  const userPassword = validatePassword(userData.password);
  if (!userData.firstName) {
    isValid = false;
    validationErrors.firstName = 'please enter your first name';
  }
  if (!userData.lastName) {
    isValid = false;
    validationErrors.lastName = 'please enter your last name';
  }
  if (!userData.userName) {
    isValid = false;
    validationErrors.userName = 'please enter your user name';
  }
  if (!validateEmail(userData.email)) {
    isValid = false;
    validationErrors.email = 'please enter a valid email';
  }
  if (!userPassword.isValid) {
    isValid = false;
    validationErrors.password = userPassword.message;
  }
  if (userPassword.isValid && userData.password !== userData.confirmPassword) {
    isValid = false;
    validationErrors.confirmPassword = "Password doesn't match";
  }
  return { isValid, validationErrors };
};
