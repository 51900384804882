import React from 'react';
import MainLayout from '../../layouts/MainLayout';

function contact() {
  return (
    <MainLayout>
      <h1>Contact Us</h1>
      <div>
        <p style={{ margin: '5px 0' }}>A. Senthoorran</p>
        <p style={{ margin: '5px 0' }}>0767037733</p>
        <a style={{ margin: '5px 0' }} href="mailto:hello@examanytime.com">
          hello@examanytime.com
        </a>
        <p style={{ margin: '5px 0' }}>254/1 Weerasekara Mawatha,</p>
        <p style={{ margin: '5px 0' }}>Pepiliyana</p>
        <p style={{ margin: '5px 0' }}>Colombo</p>
      </div>
    </MainLayout>
  );
}

export default contact;
