import React, { useEffect } from 'react';
import BgImg from '../../assets/images/chemistry-bg.png';
import ReadOnly from '../RichTextEditor/ReadOnly';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const handleTimer = (timer) => {
  const hr = Math.floor(timer / 60);
  const min = timer % 60;
  return <span>{hr === 0 ? `${min}min` : `${hr}hr ${min}min`}</span>;
};

const ExamCard = (props) => {
  const { populerExam } = props;
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const getDescription = (desc) => {
    let parsedDescription;
    let isError = false;

    try {
      parsedDescription = JSON.parse(desc);
    } catch (error) {
      isError = true;
      return (
        <Box p={1} m={0}>
          {desc}
        </Box>
      );
    }

    if (!isError && typeof parsedDescription == 'number') {
      return (
        <Box p={1} m={0}>
          {parsedDescription}
        </Box>
      );
    } else {
      let readonly = <ReadOnly initialValue={parsedDescription} />;
      return readonly;
    }
  };
  return (
    <div className="ex-card">
      <div className="ex-card-img">
        <img className="img-bg" src={BgImg} />
      </div>
      <a>
        <h3 className="ex-title">{populerExam.title}</h3>
      </a>
      <div className="ex-tag-block d-flex pb-4">
        <span className="tag-item mr-3 fw-800" style={{ backgroundColor: '#E2F6FF' }}>
          {populerExam.grade} {populerExam.subject}
        </span>
        <span className="duration-item d-flex align-items-center">
          <span className="clock-ic mr-1 mt--5">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 4V8.5L11.5 10M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
                stroke="#00394f"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="duration-text fw-800">{handleTimer(populerExam.durationInMinute)}</span>
        </span>
      </div>
      <div>{getDescription(populerExam.description)}</div>
      <div className="author-block d-flex align-items-center">
        <img className="author-img mr-2" src={BgImg} />
        <span className="author-name fw-600">{populerExam.teacherName}</span>
      </div>
      <div className="btm-ex-block d-flex mt-4">
        <div className="ex-price fw-800">Rs.{populerExam.sellingPrice}.00</div>
        <div className="btn-ex-buy-now ml-auto">
          <Link
            href={`/buy-exam/${populerExam.id}`}
            style={{ color: 'white', textTransform: 'none' }}
          >
            <button className="btn btn-primary px-5 fw-800">{t('buy_now')}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExamCard;
