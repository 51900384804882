import { FETCH_BALANCE_POINT } from '../utils/type';

const initialState = {
  availableBalance: ''
};
const pointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BALANCE_POINT:
      return {
        ...state,
        availableBalance: action.data
      };
    default:
      return state;
  }
};
export default pointsReducer;
