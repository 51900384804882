import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { getExamAttemptResults, postStudentFeedback } from '../../actions/examAction';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Result from '../../components/ExamResult';
import QuestionAndAnswers from '../../components/ExamResult/QuestionAndAnswers';
import ProgressBar from '../../components/Loader/PercentageBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { fetchExamPaperById } from '../../actions/examPaperAction';
import StarRating from '../../components/Rating/starRating';

class ExamResult extends Component {
  constructor() {
    super();
    this.state = {
      results: [],
      selectedQuestionId: '',
      examQuestionType: '',
      currentQuestionNo: '',
      renderQuestion: false,
      noOfQuestions: 0,
      selectedAnswer: [],
      correctAnswer: [],
      isAnswerCorrect: false,
      isLoadingResult: true,
      progressBarValue: 0,
      uploadFileURL: [],
      feedbackRating: false,
      textFeedBack: ''
    };
    this.handleNext = this.handleNext.bind(this);
    this.handleNextEssay = this.handleNextEssay.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handlePreviousEssay = this.handlePreviousEssay.bind(this);
    this.goToQuestion = this.goToQuestion.bind(this);
    this.goToResultPage = this.goToResultPage.bind(this);
    this.goToEssayQuestion = this.goToEssayQuestion.bind(this);
    this.handleRatingSubmit = this.handleRatingSubmit.bind(this);
    this.handleRatingSkip = this.handleRatingSkip.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    const { match, getExamAttemptResults, location, fetchExamPaperById } = this.props;
    let { progressBarValue } = this.state;
    const { examPaperId, examAttemptId } = match.params;

    if (location.pathname !== `/completed-exam-results/${examPaperId}/${examAttemptId}`) {
      const interval = setInterval(() => {
        progressBarValue += 20;
        this.setState({ progressBarValue }, () => {
          if (progressBarValue === 100) {
            this.setState({ feedbackRating: true });
            clearInterval(interval);
          }
        });
      }, 1000);
    } else {
      this.setState({ progressBarValue: 100 });
    }
    if (examAttemptId && examPaperId) {
      fetchExamPaperById(examPaperId, () => {
        getExamAttemptResults(examAttemptId, () => {
          this.setState({ isLoadingResult: false });
        });
      });
    }
  }

  componentDidUpdate(preProps) {
    const { examResults, examPaperDetails } = this.props;
    const { results } = this.state;
    if (preProps.examResults !== examResults) {
      const keys = Object.keys(examResults.questionAttemptMap);
      if (keys && keys.length > 0) {
        keys.forEach((key) => {
          const questionAttempt = examResults.questionAttemptMap[key];
          results.push(questionAttempt);
        });
        this.setState({ results, noOfQuestions: results.length });
      }
    }
    if (preProps.examPaperDetails != examPaperDetails) {
      this.setState({ examQuestionType: examPaperDetails.category });
    }
  }

  goToQuestion(e, questionData, questionNo) {
    e.preventDefault();
    const markedAnswer = [];
    const correctAnswer = [];
    const findMarkedAnswerIndex = questionData.answers.findIndex((a) => a === true);
    markedAnswer.push(findMarkedAnswerIndex + 1);

    const findCorrectAnswerIndex = questionData.validAnswers.findIndex((a) => a === true);
    correctAnswer.push(findCorrectAnswerIndex + 1);
    if (questionData.questionId && questionNo) {
      this.setState({
        selectedQuestionId: questionData.questionId,
        currentQuestionNo: questionNo,
        renderQuestion: true,
        selectedAnswer: markedAnswer,
        isAnswerCorrect: questionData.correct,
        correctAnswer
      });
    }
  }
  goToEssayQuestion(e, questionData, questionNo) {
    e.preventDefault();
    if (questionData.questionId && questionNo) {
      this.setState({
        selectedQuestionId: questionData.questionId,
        currentQuestionNo: questionNo,
        uploadFileURL: questionData.essayAnswers,
        renderQuestion: true
      });
    }
  }

  handleNext(e) {
    e.preventDefault();
    const { results, selectedQuestionId } = this.state;
    if (results && results.length > 0) {
      const currentQuestionIndex = results.findIndex(
        (result) => result.questionId === selectedQuestionId
      );
      const nextQuestionId = results[currentQuestionIndex + 1].questionId;
      const markedAnswer = [];
      const correctAnswer = [];
      const findMarkedAnswerIndex = results[currentQuestionIndex + 1].answers.findIndex(
        (a) => a === true
      );
      const isAnswerCorrect = results[currentQuestionIndex + 1].correct;
      markedAnswer.push(findMarkedAnswerIndex + 1);

      const findCorrectAnswerIndex = results[currentQuestionIndex + 1].validAnswers.findIndex(
        (a) => a === true
      );
      correctAnswer.push(findCorrectAnswerIndex + 1);
      this.setState({
        selectedQuestionId: nextQuestionId,
        currentQuestionNo: currentQuestionIndex + 2,
        selectedAnswer: markedAnswer,
        isAnswerCorrect,
        correctAnswer
      });
    }
  }
  handleNextEssay(e) {
    e.preventDefault();
    const { results, selectedQuestionId } = this.state;
    if (results && results.length > 0) {
      const currentQuestionIndex = results.findIndex(
        (result) => result.questionId === selectedQuestionId
      );
      const nextQuestionId = results[currentQuestionIndex + 1].questionId;
      this.setState({
        selectedQuestionId: nextQuestionId,
        currentQuestionNo: currentQuestionIndex + 2,
        uploadFileURL: results[currentQuestionIndex + 1].essayAnswers
      });
    }
  }
  handlePrevious(e) {
    e.preventDefault();
    const { results, selectedQuestionId } = this.state;
    if (results && results.length > 0) {
      const currentQuestionIndex = results.findIndex(
        (result) => result.questionId === selectedQuestionId
      );
      const previousQuestionId = results[currentQuestionIndex - 1].questionId;
      const markedAnswer = [];
      const correctAnswer = [];
      const findMarkedAnswerIndex = results[currentQuestionIndex - 1].answers.findIndex(
        (a) => a === true
      );
      markedAnswer.push(findMarkedAnswerIndex + 1);
      const isAnswerCorrect = results[currentQuestionIndex - 1].correct;
      const findCorrectAnswerIndex = results[currentQuestionIndex - 1].validAnswers.findIndex(
        (a) => a === true
      );
      correctAnswer.push(findCorrectAnswerIndex + 1);
      this.setState({
        selectedQuestionId: previousQuestionId,
        currentQuestionNo: currentQuestionIndex,
        selectedAnswer: markedAnswer,
        isAnswerCorrect,
        correctAnswer
      });
    }
  }
  handlePreviousEssay(e) {
    e.preventDefault();
    const { results, selectedQuestionId } = this.state;
    if (results && results.length > 0) {
      const currentQuestionIndex = results.findIndex(
        (result) => result.questionId === selectedQuestionId
      );
      const previousQuestionId = results[currentQuestionIndex - 1].questionId;

      this.setState({
        selectedQuestionId: previousQuestionId,
        currentQuestionNo: currentQuestionIndex,
        uploadFileURL: results[currentQuestionIndex - 1].essayAnswers
      });
    }
  }
  goToResultPage(e) {
    e.preventDefault();
    this.setState({ selectedQuestionId: '', currentQuestionNo: 0, renderQuestion: false });
  }
  handleRatingSubmit(difficulty, uniquenes, timing) {
    const { postStudentFeedback, match } = this.props;
    const { textFeedBack } = this.state;
    const { examAttemptId } = match.params;
    const feedback = {
      difficulty: difficulty,
      uniqueness: uniquenes,
      timing: timing,
      textFeedBack: textFeedBack
    };
    postStudentFeedback(examAttemptId, feedback, () => {
      this.setState({ feedbackRating: false });
    });
  }
  handleRatingSkip() {
    this.setState({ feedbackRating: false });
  }
  handleChange(e) {
    const { value } = e.target;
    this.setState({ textFeedBack: value });
  }
  render() {
    const {
      results,
      selectedQuestionId,
      currentQuestionNo,
      noOfQuestions,
      selectedAnswer,
      renderQuestion,
      isAnswerCorrect,
      correctAnswer,
      progressBarValue,
      isLoadingResult,
      examQuestionType,
      uploadFileURL,
      feedbackRating
    } = this.state;
    const { examResults, examPaperDetails } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Container
          className="main-container CompleteExamResult CompleteExamResult2k CompleteExamResult4k"
          style={{ marginTop: 100 }}
        >
          {progressBarValue < 100 && !isLoadingResult ? (
            <ProgressBar value={progressBarValue} />
          ) : (
            <>
              {renderQuestion ? (
                <QuestionAndAnswers
                  currentQuestionId={selectedQuestionId}
                  currentQuestionNo={currentQuestionNo}
                  selectedAnswer={selectedAnswer}
                  correctAnswer={correctAnswer}
                  noOfQuestions={noOfQuestions}
                  isAnswerCorrect={isAnswerCorrect}
                  uploadFileURL={uploadFileURL}
                  examPaperDetails={examPaperDetails}
                  examQuestionType={examQuestionType}
                  handleNext={this.handleNext}
                  handleNextEssay={this.handleNextEssay}
                  handlePrevious={this.handlePrevious}
                  handlePreviousEssay={this.handlePreviousEssay}
                  goToResultPage={this.goToResultPage}
                />
              ) : (
                <Result
                  resultList={results}
                  examResultData={examResults}
                  noOfQuestions={noOfQuestions}
                  examQuestionType={examQuestionType}
                  goToQuestion={this.goToQuestion}
                  goToEssayQuestion={this.goToEssayQuestion}
                />
              )}
            </>
          )}
          <StarRating
            open={feedbackRating}
            handleRatingSubmit={this.handleRatingSubmit}
            handleRatingSkip={this.handleRatingSkip}
            handleChange={this.handleChange}
          />
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  examResults: state.exam.examResults,
  examPaperDetails: state.examPaper.examPaperDetails
});

const mapDispatchToProps = {
  getExamAttemptResults,
  fetchExamPaperById,
  postStudentFeedback
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamResult);
