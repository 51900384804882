import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import keycloakService from './keycloakService';
import { createTheme, ThemeProvider } from '@material-ui/core'; //createMuiTheme
import './utils/languageSupport';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1363DF'
    },
    secondary: {
      main: '#ffcc80'
    }
  }
});

// const renderApp = () => {
//   ReactDOM.render(
//     <React.StrictMode>
//       <Provider store={store}>
//         <ThemeProvider theme={theme}>
//           <App />
//         </ThemeProvider>
//       </Provider>
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// };
const renderApp = () => {
  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container);
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  );
};

keycloakService.initKeyCloak(renderApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
