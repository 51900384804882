import React, { Fragment, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import ReadOnly from '../RichTextEditor/ReadOnly';
import { fetchExamQuestionById } from '../../actions/examPaperAction';
import AnswerList from './AnswerList';
import 'react-photo-view/dist/react-photo-view.css';
import DesignAnnotation from '../DesignAnnotation';
import { useTranslation } from 'react-i18next';

function findAnswers(answers) {
  const answersData = [];
  if (answers && answers.length) {
    answers.forEach((data, index) => {
      const initialValue = JSON.parse(data.answer);
      answersData.push({ initialValue, id: index });
    });
  }
  return answersData;
}

const scrole = {
  overflowY: 'auto'
};

const QuestionAndAnswers = (props) => {
  const [initialValue, setInitialValue] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]);
  const [answers, setAnswers] = useState([]);
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const {
    currentQuestionId,
    currentQuestionNo,
    handleNext,
    handleNextEssay,
    handlePrevious,
    handlePreviousEssay,
    selectedAnswer,
    noOfQuestions,
    isBtnClicked,
    goToResultPage,
    isAnswerCorrect,
    correctAnswer,
    uploadFileURL,
    examQuestionType,
    examPaperDetails
  } = props;
  const dispatch = useDispatch();
  const { questionDetails } = useSelector((state) => state.examPaper);
  let background = '';
  if (isAnswerCorrect) {
    background = '#99cc99';
  } else if (!isAnswerCorrect == true && !selectedAnswer[0] == 0) {
    background = '#FFC2C7';
  } else if (!isAnswerCorrect == true && selectedAnswer[0] == 0) {
    background = '#f5ea92';
  }

  useEffect(() => {
    if (currentQuestionId) {
      dispatch(fetchExamQuestionById(currentQuestionId));
    }
  }, [currentQuestionId]);

  useEffect(() => {
    if (questionDetails.question) {
      setInitialValue(JSON.parse(questionDetails.question));
    }
    if (questionDetails.answers) {
      const allAnswers = findAnswers(questionDetails.answers);
      setAnswers(allAnswers);
    }
  }, [questionDetails]);

  const questionInitialBlock = [
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ];

  const handleGrpQuestion = (index) => {
    let questionObj = { question: questionInitialBlock, show: 'none' };
    const grpQuestion = examPaperDetails.groupQuestions;
    if (grpQuestion != null) {
      for (let i = 0; i < grpQuestion.length; i++) {
        const item = grpQuestion[i];
        if (item.startQuestion <= index && index <= item.endQuestion) {
          questionObj = {
            question: JSON.parse(item.grpQuestion),
            show: ''
          };
          break;
        } else {
          questionObj = { question: questionInitialBlock, show: 'none' };
        }
      }
    }
    return questionObj;
  };

  return (
    <Fragment>
      <Fragment>
        <Card style={{ backgroundColor: background }} className="ex-card">
          <CardContent>
            <FormLabel component="legend" className="text-black">
              {t('question')} - {currentQuestionNo}
            </FormLabel>
            <strong>{selectedAnswer[0] == 0 ? t('this_question_unanswered') : ''}</strong>
            {examPaperDetails.groupQuestions != null && (
              <div style={{ display: handleGrpQuestion(currentQuestionNo)?.show, margin: '10px' }}>
                <p>
                  <strong>Group Question</strong>
                </p>
                <Grid item xs={12} sm={12}>
                  <ReadOnly initialValue={handleGrpQuestion(currentQuestionNo).question} />
                </Grid>
              </div>
            )}
            <ReadOnly initialValue={initialValue} />
            {examQuestionType == 'MCQ' ? (
              <>
                <div style={scrole} data-spy="scroll">
                  <AnswerList
                    isAnswerCorrect={isAnswerCorrect}
                    answers={answers}
                    selectedAnswer={selectedAnswer}
                    correctAnswer={correctAnswer}
                  />
                </div>
              </>
            ) : (
              uploadFileURL.length >= 1 && (
                <DesignAnnotation
                  uploadFileURL={uploadFileURL}
                  currentQuestionId={currentQuestionId}
                />
              )
            )}
          </CardContent>
        </Card>
        <Grid style={{ marginTop: 30, marginBottom: 30 }} container spacing={3}>
          <Grid item xs={6} sm={6}>
            <Button
              onClick={examQuestionType == 'MCQ' ? handlePrevious : handlePreviousEssay}
              disabled={currentQuestionNo === 1 || isBtnClicked}
              style={{ float: 'left' }}
              color="primary"
              className="btn btn-secondary px-4"
            >
              <ArrowBackIcon /> {t('previous')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              onClick={examQuestionType == 'MCQ' ? handleNext : handleNextEssay}
              disabled={noOfQuestions === currentQuestionNo || isBtnClicked}
              style={{ float: 'right' }}
              color="primary"
              className="btn btn-secondary px-4"
            >
              {t('next')} <ArrowForwardIcon />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} className="mbt-5">
            <Button
              onClick={goToResultPage}
              style={{ float: 'left' }}
              color="primary"
              variant="primary"
              className="btn btn-primary px-4 text-white"
            >
              {t('go_to_results')}
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    </Fragment>
  );
};

export default QuestionAndAnswers;
