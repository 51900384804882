import React, { useEffect } from 'react';
import ExamCard from '../ExamCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPopulerExams } from '../../actions/examAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import keycloakService from '../../keycloakService';
import { useTranslation } from 'react-i18next';
// import { Link } from '@material-ui/core';
const PopularExam = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  const history = useHistory();
  const populerExam = useSelector((state) => state.exam.populerExam);
  useEffect(() => {
    dispatch(fetchPopulerExams());
  }, []);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const getStart = () => {
    const isLoggedIn = keycloakService.isLoggedIn();
    if (isLoggedIn) {
      window.scrollTo(0, 0);
      history.push('/exams');
    } else {
      history.push('/sign-up');
    }
  };
  return (
    <section className="container-fluid  max-container popular-exam-section">
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>{t('populer_exams')}</h2>
        </div>
      </div>
      <div className="row mt-5">
        {Object.keys(populerExam)?.length >= 1 &&
          Object.values(populerExam).map((populerExam, index) => (
            <div className="col-lg-4" data-aos="zoom-in-up" key={index}>
              <ExamCard populerExam={populerExam} />
            </div>
          ))}
      </div>
      <div className="row mt-5 mb-5" data-aos="fade-in">
        <div className="col-lg-12 text-center d-flex justify-content-center">
          <button
            className="btn btn-primary py-2 px-4 mt-5"
            data-aos="zoom-in-up"
            onClick={() => getStart()}
          >
            <span className="mr-3">{t('view_all_exams')}</span>
            <span>
              <svg
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="23" cy="23" r="23" fill="#fff" />
                <path
                  d="M16.4286 23H29.5714M29.5714 23L24.6429 18.0714M29.5714 23L24.6429 27.9286"
                  stroke="#00394f"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default PopularExam;
