import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    marginBottom: 20,
    flexGrow: 1
  }
});

export default function CustomizedProgressBars(props) {
  const classes = useStyles();
  const { value } = props;
  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={value} />
      <Typography
        style={{
          position: 'absolute',
          color: 'black',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        Loading... ({value}%)
      </Typography>
    </div>
  );
}
