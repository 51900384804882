import React, { Component, Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PopularExam from '../../components/PopularExam';
import WhyExamanytime from '../../components/WhyExamanytime';

import CssBaseline from '@material-ui/core/CssBaseline';
import JoinCommunity from '../../components/JoinCommunity';

import HomeBanner from '../../components/HomeBanner/HomeBanner';
import ReactGA from 'react-ga4';
import HomeJoyRide from '../../components/JoyRide/HomeJoyRide';

class Home extends Component {
  constructor() {
    super();
    localStorage.setItem('NavName', 'Home');
  }
  componentDidMount() {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }
  render() {
    return (
      <Fragment>
        <CssBaseline />
        <HomeJoyRide>
          <Header />
          <HomeBanner />
          <PopularExam />
          <WhyExamanytime />
          <JoinCommunity />
          <Footer />
        </HomeJoyRide>
      </Fragment>
    );
  }
}
export default Home;
