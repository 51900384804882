import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, authHeader } from '../../utils/helper';
import keycloakService from '../../keycloakService';
import MainLayout from '../../layouts/MainLayout';
import { Box, Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { LocalPhone, Security } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Payment } from '@material-ui/icons';
import { StayCurrentPortrait } from '@material-ui/icons';

const useStyle = makeStyles({
  priceBox: {
    alignContent: 'center',
    justifyContent: 'space-between',
    display: 'flex',

    padding: '10px',
    borderRadius: '10px',
    border: '1px black solid'
  }
});
function MobilePay() {
  const clasess = useStyle();
  const [ph, setPh] = useState('');
  const [otp, setOtp] = useState('');
  const [responce, setResponce] = useState(null);
  const [fixedAmount, setFixedAmount] = useState('');
  const [showMobile, sethSowMobil] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();
  const isLoggedIn = keycloakService.isLoggedIn();
  const [loading, setLoading] = useState(false);
  const [phSuccess, setPhSuccess] = useState();
  const [display, setDisplay] = useState();
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const getToken = async () => {
    const isLoggedIn = keycloakService.isLoggedIn();
    if (isLoggedIn) {
      await keycloakService.updateToken();
      const token = keycloakService.getToken();
      return token;
    }
    return keycloakService.login();
  };
  const handlePhoneNum = () => {
    const firstFourDigits = ph.substring(0, 4);
    const containsPattern = firstFourDigits.includes('9476') || firstFourDigits.includes('9477');
    if (containsPattern) {
      onSignup();
    } else {
      setPhSuccess(t('enter_dialog_num'));
    }
  };
  useEffect(() => {
    const handleScreenSize = () => {
      if (window.innerWidth <= 768) {
        console.log('window resized 768');
        setDisplay('block');
      } else if (window.innerWidth > 768) {
        setDisplay('flex');
      }
    };
    handleScreenSize();

    window.addEventListener('resize', handleScreenSize);

    return () => {
      window.removeEventListener('resize', handleScreenSize);
    };
  }, []);
  const onSignup = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API_URL}/payment/mobile?amount=${fixedAmount}&tel=${ph}`,
        {},
        authHeader(token)
      );
      toast.success(t('otp_msg'));
      setResponce(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(t('error_msg'));
      setLoading(false);
    }
  };
  const onOTPVerify = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API_URL}/payment/otp?otp=${otp}&paymentId=${responce}`,
        {},
        authHeader(token)
      );
      console.log(response.data);
      toast.success(t('subscribe_msg'));
      setLoading(false);
      history.push('/profile');
    } catch (error) {
      console.error(error);
      toast.error(t('error_msg'));
      setLoading(false);
    }
  };
  const handleButtons = async (clickedBtn) => {
    if (fixedAmount === '') {
      setErrorMsg('Please Choose One of Points');
      setTimeout(() => {
        setErrorMsg('');
      }, 3000);
    } else {
      if (clickedBtn == 'credit') {
        setLoading(true);
        try {
          const token = await getToken();
          const responce = await axios.post(
            `${API_URL}/payment/buy-points?amount=${fixedAmount}`,
            {},
            authHeader(token)
          );
          setLoading(false);
          window.location.href = responce.data;
        } catch (error) {
          console.error(error);
        }
      } else if (clickedBtn == 'mobile') {
        sethSowMobil(!showMobile);
      }
    }
  };
  const amountFixing = () => {
    return (
      <Fragment>
        <h6>{t('pick_one')}</h6>
        <Grid spacing={3} container>
          <Grid item xs={6} sm={6}>
            <Box
              onClick={() => setFixedAmount('100')}
              className={clasess.priceBox}
              style={{ backgroundColor: fixedAmount == '100' ? 'gray' : '' }}
            >
              <label>LKR 100 </label>
              <label>100 Pts </label>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box
              onClick={() => setFixedAmount('300')}
              className={clasess.priceBox}
              style={{ backgroundColor: fixedAmount == '300' ? 'gray' : '' }}
            >
              <label>LKR 300 </label>
              <label>300 Pts </label>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box
              onClick={() => setFixedAmount('500')}
              className={clasess.priceBox}
              style={{ backgroundColor: fixedAmount == '500' ? 'gray' : '' }}
            >
              <label>LKR 500 </label>
              <label>500 Pts </label>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box
              onClick={() => setFixedAmount('1000')}
              className={clasess.priceBox}
              style={{ backgroundColor: fixedAmount == '1000' ? 'gray' : '' }}
            >
              <label>LKR 1000 </label>
              <label>1000 Pts </label>
            </Box>
          </Grid>
        </Grid>
        {errorMsg != '' && <p style={{ color: 'red' }}>{errorMsg}</p>}
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={6} sm={6}>
            <Box
              style={{
                border: '1px solid black',
                borderRadius: '10px',
                padding: '50px 10px 50px 10px',
                display: display
              }}
              onClick={() => handleButtons('credit')}
            >
              <h6>{t('Credit/Debit')}</h6>
              {loading && <Spinner animation="border" size="sm" />}
              <Payment style={{ marginLeft: 'auto', color: '#2E8BC0' }} />
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box
              style={{
                padding: '50px 10px 50px 10px',
                display: display,
                border: '1px solid black',
                borderRadius: '10px'
              }}
              onClick={() => handleButtons('mobile')}
              disabled={fixedAmount === ''}
            >
              <h6>{t('mobile_payment')}</h6>
              <StayCurrentPortrait style={{ marginLeft: 'auto', color: '#2E8BC0' }} />
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
  const renderMobileNumberForm = () => {
    return (
      <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4">
        <div
          className="text-white d-inline-block mx-auto p-3 rounded-circle"
          style={{ backgroundColor: '#17a2b8' }}
        >
          <LocalPhone size={30} />
        </div>
        <label className="font-weight-bold text-xl text-center mt-1">
          {t('enter_mobile_number')}
        </label>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mb-2">
            <PhoneInput country={'lk'} value={ph} onChange={setPh} />
          </div>
          <div className="mt-2">
            <Button
              onClick={handlePhoneNum}
              className="d-flex flex-row align-items-center justify-content-center text-black py-2.5 rounded p-2"
              style={{
                width: '300px',
                backgroundColor: '#17a2b8',
                border: '#17a2b8'
              }}
              disabled={ph.length < 11 || 11 < ph.length}
            >
              {loading && <Spinner animation="border" size="sm" />}
              <span>{t('send_code_via_SMS')}</span>
            </Button>
          </div>
        </div>
        {phSuccess && (
          <p style={{ color: 'red', fontSize: '24', marginLeft: '20%' }}>{phSuccess}</p>
        )}
      </div>
    );
  };
  return (
    <MainLayout index="1">
      <div className="mt-5">
        <Toaster toastOptions={{ duration: 4000 }} />
      </div>
      {isLoggedIn ? (
        <Card style={{ marginLeft: '3%', marginRight: '3%' }}>
          <CardContent>
            <Grid>
              <div className="d-flex align-items-center justify-content-center">
                <div className="w-60 d-flex flex-column gap-5 rounded-lg p-4">
                  <div className="text-center font-weight-medium text-white fs-3 mb-6 .col-xs-3">
                    <h1>{t('welcome_to_point')}</h1>
                    <h1>{t('mobile_points_topup')}</h1>
                  </div>
                  {showMobile == false ? (
                    amountFixing()
                  ) : responce === null ? (
                    renderMobileNumberForm()
                  ) : (
                    <div className="w-80 d-flex flex-column gap-5 rounded-lg p-4">
                      <div
                        className="text-white d-inline-block mx-auto p-3 rounded-circle"
                        style={{ backgroundColor: '#17a2b8' }}
                      >
                        <Security size={30} />
                      </div>
                      <label className="font-weight-bold text-xl text-center mt-1">
                        {t('enter_otp')}
                      </label>
                      <div className="d-flex justify-content-center align-items-center">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          numInputs={6}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{
                                width: '40px',
                                height: '40px',
                                fontSize: '16px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                textAlign: 'center',
                                margin: '2px',
                                padding: '5px'
                              }}
                            />
                          )}
                          containerStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        />
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <Button
                          onClick={onOTPVerify}
                          className="d-flex flex-row align-items-center justify-content-center py-2.5 text-black rounded p-2"
                          style={{ width: '300px', backgroundColor: '#17a2b8', border: '#17a2b8' }}
                          disabled={otp.length < 6}
                        >
                          {loading && <Spinner animation="border" size="sm" />}
                          <span>{t('verify_otp')}</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        ''
      )}
    </MainLayout>
  );
}

export default MobilePay;
