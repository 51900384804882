import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExamAttemptDetails } from '../../actions/examAction';
import MarkedAnswers from './MarkedAnswers';
import UploadedAnswers from './UploadedAnswers';
import { useTranslation } from 'react-i18next';

const getExamAnswersStatus = (examAttemptDetails) => {
  const markedAnswers = [];
  if (examAttemptDetails && examAttemptDetails.questionAttemptMap) {
    Object.keys(examAttemptDetails.questionAttemptMap).map((answerKey, i) => {
      const answerObj = examAttemptDetails.questionAttemptMap[answerKey];
      let status = 'UNANSWERED';
      if (answerObj.answers && answerObj.answers.length > 0) {
        status = 'ANSWERED';
      } else if (answerObj.answerFiles && answerObj.answerFiles.length > 0) {
        status = 'ANSWERED';
      } else if (answerObj.deferred) {
        status = 'DOUBTFUL';
      }
      markedAnswers.push({
        questionNo: i + 1,
        status,
        questionId: answerObj.questionId,
        answers: answerObj.answers,
        answerFiles: answerObj.answerFiles,
        deferred: answerObj.deferred
      });
    });
  }
  return markedAnswers;
};

const Review = (props) => {
  const [markedAnswerList, setMarkedAnswerList] = useState([]);
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const dispatch = useDispatch();
  const { examAttemptDetails } = useSelector((state) => state.exam);
  const { goToQuestion, handleSubmit, examAttemptId, examQuestionType, noOfQuestions } = props;

  useEffect(() => {
    dispatch(getExamAttemptDetails(examAttemptId));
  }, [examAttemptId]);

  useEffect(() => {
    const questionsStatusList = getExamAnswersStatus(examAttemptDetails);
    setMarkedAnswerList(questionsStatusList);
  }, [examAttemptDetails]);

  return (
    <Fragment>
      <h4>{t('review_question')}</h4>
      {examQuestionType == 'MCQ' ? (
        <MarkedAnswers
          answerList={markedAnswerList}
          goToQuestion={goToQuestion}
          handleSubmit={handleSubmit}
          noOfQuestions={noOfQuestions}
        />
      ) : (
        <UploadedAnswers
          answerList={markedAnswerList}
          goToQuestion={goToQuestion}
          handleSubmit={handleSubmit}
        />
      )}
    </Fragment>
  );
};

export default Review;
