import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainLayout from '../../layouts/MainLayout';
import { getSubscriptionResults } from '../../actions/filterAction';
import keycloakService from '../../keycloakService';
import SubScriptionResults from '../../components/SubScriptionResults';

class PendingExams extends Component {
  constructor() {
    super();
    this.state = {
      pendingExamsList: []
    };
  }
  componentDidMount() {
    const { getSubscriptionResults } = this.props;
    const { isLoggedIn } = keycloakService;
    if (isLoggedIn()) {
      const statuses = 'PENDING,STARTED,REVIEW';
      getSubscriptionResults(statuses);
    }
  }

  componentDidUpdate(preProps) {
    const { subscriptionResult } = this.props;
    if (subscriptionResult !== preProps.subscriptionResult) {
      this.setState({ pendingExamsList: subscriptionResult });
    }
  }
  render() {
    const { pendingExamsList } = this.state;
    return (
      <MainLayout index={pendingExamsList.length}>
        <SubScriptionResults examList={pendingExamsList} title="Pending Exams" />
      </MainLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  subscriptionResult: state.filter.subscriptionResult
});

const mapDispatchToProps = {
  getSubscriptionResults
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingExams);
