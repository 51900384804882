import { SET_LANGUAGE } from '../utils/type';

const initialState = {
  selectedLanguage: localStorage.getItem('language') || 'en'
};

const localizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.data
      };
    default:
      return state;
  }
};

export default localizationReducer;
