import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useCookies } from 'react-cookie';
import { Link } from '@material-ui/core';
import Cookies from '../../assets/images/cookie.png';
import { Close } from '@material-ui/icons';

function CookiesModel(props) {
  const { open, setOpen } = props;
  const [cookies, setCookies] = useCookies(['CookiesModel']);

  const giveCookieConsent = () => {
    setCookies('CookiesModel', true, { path: '/' });
    setOpen(false);
  };

  const handleClose = () => {
    console.log('cookies', cookies);
  };

  return (
    <div style={{ borderRadius: '100px' }}>
      <Dialog open={open} aria-labelledby="responsive-dialog-title">
        <DialogContent style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-20px' }}>
            <Close onClick={() => setOpen(false)} />
          </div>
          <DialogContentText>
            <div>
              <img
                src={Cookies}
                style={{
                  width: '100px',
                  height: '100px',
                  marginBottom: '20px'
                }}
                alt="Cookies"
              />
              <h4>Cookies And Privacy</h4>
              <p>
                <strong>
                  This Website Uses Cookies To Ensure You Get the Best Experience on our Website
                </strong>
              </p>
            </div>
          </DialogContentText>
          <div>
            <button
              className="btn btn-primary py-2 px-4 mt-5"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              onClick={giveCookieConsent}
              style={{
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%'
              }}
            >
              Accept All Cookies
            </button>

            <div
              style={{
                justifyContent: 'space-between',
                textAlign: 'center',
                display: 'block',
                alignItems: 'center',
                alignContent: 'center'
              }}
            >
              <Link onClick={handleClose}></Link>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CookiesModel;
