import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 60,
    borderRadius: 40
  },
  inputRoot: {
    borderRadius: 40
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  margincontrole: {
    marginRight: 60
  },
  filterBtn: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10
  },
  historyCard: {
    marginTop: 10,
    marginBottom: 10
  }
}));

const options = (data) => {
  if (data && data.length) {
    return data.map((item, index) => (
      <MenuItem key={`test-${index}`} value={item.name} data-id={item.id}>
        {item.name}
      </MenuItem>
    ));
  }
};

const Filter = (props) => {
  const { filterKey, handleFilterChange, selectedValues, index, filterName } = props;
  const classes = useStyles();
  const selectedValue = selectedValues[filterKey] || '';
  const [filterData, setFilterData] = useState([]);
  const tempFill = useSelector((state) => state.cascadeFilter.cascadeFilter);
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  useEffect(() => {
    if (tempFill.length >= 1) {
      if (
        (tempFill[0].group == 'Language' && filterKey === 'Language') ||
        (tempFill[0].group == 'Grade' && filterKey === 'Grade') ||
        (tempFill[0].group == 'Subject' && filterKey === 'Subject') ||
        (tempFill[0].group == 'Teacher' && filterKey === 'Teacher')
      ) {
        setFilterData(tempFill);
      }
    } else if (tempFill.length == 0) {
      if (
        Object.keys(selectedValues).length == 1 &&
        (filterKey === 'Grade' || filterKey === 'Subject' || filterKey === 'Teacher')
      ) {
        setFilterData(tempFill);
      } else if (
        Object.keys(selectedValues).length == 2 &&
        (filterKey === 'Subject' || filterKey === 'Teacher')
      ) {
        setFilterData(tempFill);
      } else if (Object.keys(selectedValues).length == 3 && filterKey === 'Teacher') {
        setFilterData(tempFill);
      }
    }
  });
  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl} style={{ width: '150px' }}>
        <InputLabel id="demo-simple-select-outlined-label">{filterName}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name={filterKey}
          value={selectedValue}
          filterId=""
          onChange={(e) => handleFilterChange(e, index)}
          label={filterKey}
        >
          <MenuItem value="">
            <em>
              {t('filter_select')} {t(filterKey)}
            </em>
          </MenuItem>
          {filterData.length >= 1 && filterData ? options(filterData) : ''}
        </Select>
      </FormControl>
    </div>
  );
};

export default Filter;
