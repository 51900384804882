import React, { Component, Fragment } from 'react';
import FilterCard from '../../components/TrendingFilterSearch/FilterCard';
import ResultsGrid from '../../components/TrendingFilterSearch/ResultsGrid';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { withStyles } from '@material-ui/core/styles';
import keycloakService from '../../keycloakService';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import JoinCommunity from '../../components/JoinCommunity';
import {
  fetchSearchResult,
  fetchAllSelectors,
  clearSearchData,
  getSubscriptionResults,
  cascadeFilter
} from '../../actions/filterAction';
import ExamJoyRide from '../../components/JoyRide/ExamJoyRide';

const styles = {
  resultGrid: {
    marginTop: 2
  }
};

class TrendingFilterSearch extends Component {
  constructor() {
    super();
    localStorage.setItem('NavName', 'Exams');
    this.state = {
      selectedValues: {},
      page: 0,
      pageSize: 5,
      isDataLoading: false,
      filterIndex: 0,
      pendingExams: [],
      rootFilter: []
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }
  componentDidMount() {
    const isLoggedIn = keycloakService.isLoggedIn();
    let { selectedValues, page, pageSize } = this.state;
    const { fetchAllSelectors, getSubscriptionResults, cascadeFilter, fetchSearchResult } =
      this.props;
    fetchAllSelectors();
    const searchData = {
      query: selectedValues,
      page,
      pageSize
    };
    this.setState({ isDataLoading: true });
    fetchSearchResult(searchData, () => {
      this.setState({ isDataLoading: false });
    });
    cascadeFilter('root');
    if (isLoggedIn) {
      getSubscriptionResults();
    }
  }
  componentDidUpdate(preProps) {
    const { subscriptionResult, casFilter } = this.props;
    if (subscriptionResult !== preProps.subscriptionResult) {
      const findPendingResults = subscriptionResult.filter((result) => result.status === 'PENDING');
      this.setState({ pendingExams: findPendingResults });
    }
    if (casFilter.length >= 1 && casFilter !== preProps.casFilter) {
      this.setState({ rootFilter: casFilter });
    }
  }

  async handleFilterChange(e, filterIndex) {
    let { selectedValues, page, pageSize } = this.state;
    const { fetchSearchResult, cascadeFilter } = this.props;
    const selectedFilter = e.target.name;
    const selectedFilterValue = e.target.value;
    if (
      selectedFilter == 'Language' &&
      selectedValues['Grade'] &&
      selectedValues['Subject'] &&
      selectedValues['Teacher']
    ) {
      delete selectedValues.Teacher;
      delete selectedValues.Subject;
      delete selectedValues.Grade;
    } else if (
      selectedFilter == 'Grade' &&
      selectedValues['Subject'] &&
      selectedValues['Teacher']
    ) {
      delete selectedValues.Teacher;
      delete selectedValues.Subject;
    } else if (selectedFilter == 'Subject' && selectedValues['Teacher']) {
      delete selectedValues.Teacher;
    }
    selectedValues[selectedFilter] = selectedFilterValue;
    console.log(selectedValues);
    const searchData = {
      query: selectedValues,
      page,
      pageSize
    };
    this.setState({ isDataLoading: true });
    fetchSearchResult(searchData, () => {
      this.setState({ isDataLoading: false });
    });
    this.setState({
      selectedValues,
      filterIndex
    });
    const itemId = event.target.dataset?.id;
    cascadeFilter(itemId);
  }

  clearFilter() {
    const { fetchSearchResult } = this.props;
    this.setState({ selectedValues: {} });
    const searchData = {
      query: {},
      page: 0,
      pageSize: 5
    };
    this.setState({ isDataLoading: true });
    fetchSearchResult(searchData, () => {
      this.setState({ isDataLoading: false });
    });
    this.setState({ page: 0 });
  }

  handleLoadMore() {
    let { page, pageSize, selectedValues } = this.state;
    const { fetchSearchResult } = this.props;
    const searchData = {
      query: selectedValues,
      page: page + 1,
      pageSize
    };
    this.setState({ isDataLoading: true });
    fetchSearchResult(
      searchData,
      () => {
        this.setState({ page: page + 1, isDataLoading: false });
      },
      true
    );
  }

  hasMoreItems(data) {
    if (data.totalPages) {
      const { page } = this.state;
      return page + 1 < data.totalPages;
    }
    return false;
  }
  render() {
    const { isDataLoading, rootFilter } = this.state;
    const { classes, searchData, selectors } = this.props;
    console.log(selectors);
    const hasMoreItems = this.hasMoreItems(searchData);
    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <ExamJoyRide>
          <div className="main-content-wrapper px-0 px-lg-3 pt-5 pt-md-0">
            <FilterCard
              {...this.state}
              handleFilterChange={this.handleFilterChange}
              clearFilter={this.clearFilter}
              filtersData={selectors}
              rootFilter={rootFilter}
            />
            <hr />
            <div className={classes.resultGrid}>
              <ResultsGrid
                hasMoreItems={hasMoreItems}
                results={searchData}
                handleLoadMore={this.handleLoadMore}
                isDataLoading={isDataLoading}
              />
            </div>
          </div>
        </ExamJoyRide>
        <JoinCommunity />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  filterData: state.filter.filterData,
  searchData: state.filter.searchData,
  selectors: state.filter.selectors,
  casFilter: state.cascadeFilter.cascadeFilter
});

const mapDispatchToProps = {
  fetchSearchResult,
  fetchAllSelectors,
  clearSearchData,
  getSubscriptionResults,
  cascadeFilter
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TrendingFilterSearch));
