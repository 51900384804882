import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function LinearIndeterminate(props) {
  const classes = useStyles();
  const { isLoading } = props;
  return <div className={classes.root}>{isLoading ? <LinearProgress /> : null}</div>;
}
