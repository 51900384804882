import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    width: '100%',
    maxWidth: '120px',
    justifyContent: 'center'
  },
  listItemText: {
    display: 'flex',
    padding: '5px',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center'
  },
  answerIndicator: {
    display: 'flex',
    flexDirection: 'row',
    margin: 3,
    marginRight: 15
  },
  homeBtn: {
    border: '1px solid black',
    float: 'left',
    padding: 10,
    color: 'black',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center'
  },
  examPaperCard: {
    marginTop: 100
  }
}));

const getStatusColor = (correctAnswer) => {
  if (correctAnswer) {
    return '#59981A';
  }
  return '#FFAEBC';
};

const Results = (props) => {
  const {
    resultList,
    examResultData,
    goToQuestion,
    examQuestionType,
    goToEssayQuestion,
    noOfQuestions
    // handleOpen
  } = props;
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.examPaperCard}>
      {examQuestionType == 'MCQ' ? (
        <>
          <Grid item xs={12} sm={12} className="d-flex align-items-center">
            <h4>
              {t('results_score')}:{' '}
              {examResultData.noOfCorrectAnswers ? examResultData.noOfCorrectAnswers : 0}
              {'/'}
              {resultList.length}
            </h4>
            <h4 className="ml-auto">
              {t('score')}: {examResultData.score ? examResultData.score : ''}
            </h4>
          </Grid>
          <hr className="hr" />
          <Grid item xs={12} sm={6} className="d-flex align-items-center">
            <p className={classes.answerIndicator}>
              <CheckBoxOutlineBlankIcon
                style={{ color: '#59981A', background: '#59981A', borderRadius: '5px' }}
                className="mr-1"
              />{' '}
              {t('correct')}
            </p>
            <p className={classes.answerIndicator}>
              <CheckBoxOutlineBlankIcon
                style={{ color: '#FFAEBC', background: '#FFAEBC', borderRadius: '5px' }}
                className="mr-1"
              />{' '}
              {t('wrong')}
            </p>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={12} className="d-flex align-items-center">
            <h4>
              {t('results_score')}:{' '}
              {examResultData.noOfCorrectAnswers
                ? examResultData.noOfCorrectAnswers
                : 'awaiting...'}
              {'/'}
              {resultList.length}
            </h4>
            <h4 className="ml-auto">
              {t('score')}:{' '}
              {examResultData.score ? examResultData.score : 'Correction in progress.'}
            </h4>
          </Grid>
          <hr className="hr" />
          <Grid item xs={12} sm={6} className="d-flex align-items-center">
            <p className={classes.answerIndicator}>
              <CheckBoxOutlineBlankIcon
                style={{ color: '#59981A', background: '#59981A', borderRadius: '5px' }}
                className="mr-1"
              />{' '}
              {t('corrected_papers')}
            </p>
            <p className={classes.answerIndicator}>
              <CheckBoxOutlineBlankIcon
                style={{ color: '#FFAEBC', background: '#FFAEBC', borderRadius: '5px' }}
                className="mr-1"
              />{' '}
              {t('not_corrected_papers')}
            </p>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12}>
        <Card className="ex-card">
          <List className={classes.root}>
            {resultList.map((value, i) => {
              const labelId = `checkbox-list-label-${value}`;
              return (
                <ListItem
                  className={classes.listItem}
                  key={value.questionId}
                  role={undefined}
                  dense
                  button
                  onClick={(e) => {
                    examQuestionType == 'MCQ'
                      ? goToQuestion(e, value, i + 1)
                      : goToEssayQuestion(e, value, i + 1);
                  }}
                >
                  <ListItemIcon>
                    <div
                      className={classes.listItemText}
                      style={{
                        border: `2px solid ${getStatusColor(value.correct)}`,
                        borderRadius: '10px',
                        backgroundColor: `${getStatusColor(value.correct)}`,
                        display: 'block'
                      }}
                    >
                      <strong
                        id={labelId}
                        style={{
                          color: 'black',
                          fontSize: '30',
                          fontWeight: '800',
                          paddingLeft: '10px'
                        }}
                      >
                        Q{i + 1}
                      </strong>
                      <div
                        style={{
                          color: 'black',
                          marginTop: '3px',
                          display: 'block'
                        }}
                      >
                        <strong
                          style={{
                            color: 'black',
                            display: 'block'
                          }}
                        >
                          {t('spent')} {resultList[i].timeSpendInSeconds}s
                        </strong>
                        <strong
                          style={{
                            color: '#0C2D48',
                            display: 'block'
                          }}
                        >
                          {resultList[i].timeExpectedInSeconds == 0
                            ? ''
                            : `Expect-${resultList[i].timeExpectedInSeconds}`}
                        </strong>{' '}
                      </div>
                    </div>
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Link
          className={classes.homeBtn}
          href={`/my-learning/${'complete-exampage'}`}
          underline="none"
          // onClick={() => handleOpen()}
          disable={resultList.length <= noOfQuestions}
        >
          {t('header_completed_exams')}
        </Link>
      </Grid>
    </Grid>
  );
};

export default Results;
