import axios from 'axios';
import { API_URL, authHeader, authMutliFormHeader } from '../utils/helper';
import {
  FETCH_EXAM_PAPER_BY_ID,
  FETCH_EXAM_QUESTION_BY_ID,
  FETCH_ALL_TEACHERS,
  FETCH_TEACHER_EXAM_PAPERS,
  FETCH_ALL_QUESTIONS
} from '../utils/type';
import { getValidToken } from './filterAction';
import { displayLoader } from './loaderAction';

export const createExamPaper = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/exam/paper`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const updateExamPaper = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .put(`${API_URL}/exam/paper`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const publishExamPaper = (examPaperId, examStatus, onsuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(`${API_URL}/exam/paper/${examPaperId}?examStatus=${examStatus}`, {}, authHeader(token))
      .then(() => {
        onsuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const createQuestion = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/exam/question`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const updateQuestion = (examPaperData, onSuccess) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .put(`${API_URL}/exam/question`, examPaperData, authHeader(token))
      .then((res) => {
        dispatch(displayLoader(false));
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
        dispatch(displayLoader(false));
      });
  };
};

export const fetchSearchResult = (teacherId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/teacher/papers?teacherId=${teacherId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_TEACHER_EXAM_PAPERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchExamPaperById = (examPaperId, onsuccess = () => {}) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/paper/${examPaperId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_EXAM_PAPER_BY_ID,
          data: res.data
        });
        onsuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchExamQuestionById = (questionId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/question/${questionId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_EXAM_QUESTION_BY_ID,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchAllTeachers = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/teachers`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_TEACHERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const uploadFiles = (fileData, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/file/upload`, fileData, authMutliFormHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const deleteFile = (filepath, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .delete(`${API_URL}/file?path=${filepath}`, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchAllQuestions = (examPaperId) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/paper/${examPaperId}/questions`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_ALL_QUESTIONS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const updateQuestionStatus = (questionData, onsuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(
        `${API_URL}/exam/question/${questionData.questionId}?questionStatus=${questionData.status}`,
        {},
        authHeader(token)
      )
      .then(() => {
        onsuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
