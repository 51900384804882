import {
  GET_EXAM_ATTEMPT,
  GET_ATTEMPT_STATUS,
  GET_EXAM_ATTEMPT_RESULT,
  FETCH_POPULER_EXAMS,
  FETCH_PAST_PAPERS
} from '../utils/type';

const initialState = {
  examAttemptDetails: {
    isDataLoaded: false
  },
  examAttemptStatus: {},
  examResults: {},
  populerExam: [],
  pastPapers: []
};

const examReducer = (state = initialState, action) => {
  let examAttemptData = {};
  switch (action.type) {
    case GET_EXAM_ATTEMPT:
      examAttemptData = action.data;
      examAttemptData.isDataLoaded = true;
      return {
        ...state,
        examAttemptDetails: examAttemptData
      };
    case GET_ATTEMPT_STATUS:
      return {
        ...state,
        examAttemptStatus: action.data
      };
    case GET_EXAM_ATTEMPT_RESULT:
      return {
        ...state,
        examResults: action.data
      };
    case FETCH_POPULER_EXAMS:
      return {
        ...state,
        populerExam: action.data
      };
    case FETCH_PAST_PAPERS:
      return {
        ...state,
        pastPapers: action.data
      };
    default:
      return state;
  }
};

export default examReducer;
