import React, { createContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../components/Header';
import Footer from '../components/Footer';

export const MyContext = createContext();
export default function MainLayout(props) {
  return (
    <div>
      <CssBaseline />
      <MyContext.Provider>
        <Header />
      </MyContext.Provider>
      <main className="main-content-wrapper px-0 px-lg-3">{props.children}</main>
      <Footer />
    </div>
  );
}
