import React from 'react';
import MainLayout from '../../layouts/MainLayout';

function featured() {
  return (
    <MainLayout>
      <h1>featured Page Comming Soon...!</h1>
    </MainLayout>
  );
}

export default featured;
