import { TextField } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}));
function StarRating(props) {
  const { handleRatingSubmit, handleRatingSkip, open, handleChange } = props;
  const classes = useStyles();
  const [difficulty, setDificulty] = useState(0);
  const [uniquenes, setUniquenes] = useState(0);
  const [timing, setTiming] = useState(0);
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Dialog open={open} aria-labelledby="responsive-dialog-title">
        <DialogContent style={{ textAlign: 'center' }}>
          <h1>Rate Your Experience</h1>
          <div style={{ margin: '10px', padding: '10px' }}>
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-around',
                margin: '5px',
                padding: '5px'
              }}
            >
              <div style={{ marginRight: '20px' }}>
                <h6>Difficulty</h6>
              </div>
              <div className={classes.root}>
                <Rating
                  name="difficulty-rating"
                  size="large"
                  value={difficulty}
                  onChange={(event, difficulty) => {
                    setDificulty(difficulty);
                  }}
                  maxLength
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ justifyContent: 'flex-start' }}>bad</p>
                  <p style={{ justifyContent: 'flex-end' }}>good</p>
                </div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-around',
                margin: '5px',
                padding: '5px'
              }}
            >
              <div style={{ marginRight: '20px' }}>
                <h6>Uniqueness</h6>
              </div>
              <div className={classes.root}>
                <Rating
                  name="uniquens-rating"
                  size="large"
                  value={uniquenes}
                  onChange={(event, uniquenes) => {
                    setUniquenes(uniquenes);
                  }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ justifyContent: 'flex-start' }}>bad</p>
                  <p style={{ justifyContent: 'flex-end' }}>good</p>
                </div>
              </div>
            </div>
            <Divider />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-around',
                margin: '5px',
                padding: '5px'
              }}
            >
              <div style={{ marginRight: '20px' }}>
                <h6>Timing</h6>
              </div>
              <div className={classes.root}>
                <Rating
                  name="timing-rating"
                  size="large"
                  value={timing}
                  onChange={(event, timing) => {
                    setTiming(timing);
                  }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ justifyContent: 'flex-start' }}>bad</p>
                  <p style={{ justifyContent: 'flex-end' }}>good</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="feedback"
              label="Enter Feedback"
              id="feedback"
              onChange={handleChange}
              inputProps={{
                maxLength: 25
              }}
            />
          </div>
          <div
            style={{
              alignContent: 'center',
              justifyContent: 'space-around',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <button className="btn btn-primary py-2 px-4 mt-5" onClick={() => handleRatingSkip()}>
              Skip
            </button>
            <button
              className="btn btn-primary py-2 px-4 mt-5"
              onClick={() => handleRatingSubmit(difficulty, uniquenes, timing)}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StarRating;
