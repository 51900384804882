import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Timer from '../../components/ExamPage/Timer';
import Main from './Main';
import CssBaseline from '@material-ui/core/CssBaseline';
class ExamPage extends Component {
  constructor() {
    super();
    this.state = {
      topMargin: 0
    };
    this.handleScreenSize = this.handleScreenSize.bind(this);
  }
  handleScreenSize() {
    if (window.innerWidth <= 440) {
      this.setState({ topMargin: 175 });
      console.log('under 440');
    } else {
      this.setState({ topMargin: 100 });
    }
  }
  componentDidMount() {
    this.handleScreenSize();
    window.addEventListener('resize', this.handleScreenSize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleScreenSize);
  }
  render() {
    const { match, history } = this.props;
    const { topMargin } = this.state;
    const { examPaperId, examAttemptId } = match.params;
    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <Container className="main-container" style={{ marginTop: topMargin }}>
          <Timer examAttemptId={examAttemptId} examPaperId={examPaperId} />
          <Main examPaperId={examPaperId} examAttemptId={examAttemptId} history={history} />
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

export default ExamPage;
