import React from 'react';
import MainLayout from '../../layouts/MainLayout';

function PrivacyPolicy() {
  return (
    <MainLayout>
      <h1>Privacy Policy</h1>
      <div>
        <p>&nbsp;</p>
        <h6>About Privacy Policy</h6>
        <p>
          At www.examanytime.com, we take your privacy seriously and are committed to protecting
          your personal information. This Privacy Policy outlines how we collect, use, disclose, and
          safeguard your information when you visit our website and use our services.
        </p>
        <p>&nbsp;</p>
        <h6>Information We Collect</h6>
        <p>
          <strong>Personal Information:</strong> When you create an account on www.examanytime.com,
          we may collect personal information such as your name, email address, and other contact
          details.
        </p>
        <p>
          <strong>Payment Information:</strong> If you make a purchase on our website, we may
          collect payment information such as credit card details or other payment account
          information. However, we do not store this information on our servers. Payment
          transactions are processed through secure third-party payment gateways.
        </p>
        <p>
          <strong>Usage Information:</strong> We may collect information about how you interact with
          our website, including your browsing history, pages visited, and actions taken.
        </p>
        <p>&nbsp;</p>
        <h6>How We Use Your Information</h6>
        <p>
          <strong>To Provide Services:</strong> We use the information we collect to provide you
          with access to our website and services, including facilitating the purchase and
          completion of exam papers.
        </p>
        <p>
          <strong>To Improve Our Services:</strong> We may use your information to analyze trends,
          track user activity, and gather demographic information to improve our website, services,
          and user experience.
        </p>
        <p>
          <strong>To Communicate With You:</strong> We may use your contact information to send you
          administrative messages, updates, newsletters, or other communications related to your
          account or our services.
        </p>
        <p>&nbsp;</p>
        <h6>How We Share Your Information</h6>
        <p>
          <strong>With Teachers:</strong> If you are a student, we may share your information with
          teachers who have uploaded exam papers that you have purchased or completed for
          educational purposes.
        </p>
        <p>
          <strong>With Service Providers:</strong> We may share your information with third-party
          service providers who assist us in operating our website, conducting business, or
          servicing you, provided that they agree to keep this information confidential.
        </p>
        <p>
          <strong>For Legal Purposes:</strong> We may disclose your information in response to a
          subpoena, court order, or other legal process, or to comply with applicable laws,
          regulations, or governmental requests.
        </p>
        <p>&nbsp;</p>
        <h6>Data Security</h6>
        <p>
          We take appropriate measures to protect your personal information from unauthorized
          access, alteration, disclosure, or destruction. However, no data transmission over the
          Internet or electronic storage method is 100% secure, and we cannot guarantee the absolute
          security of your information.
        </p>
        <p>&nbsp;</p>
        <h6>Your Choices</h6>
        <p>
          You can update or delete your account information at any time by logging into your account
          settings. If you wish to opt out of receiving promotional communications from us, you can
          follow the unsubscribe instructions provided in the communication or contact us directly.
        </p>
        <p>&nbsp;</p>
        <h6>Changes to This Privacy Policy</h6>
        <p>
          We reserve the right to modify or update this Privacy Policy at any time. Any changes will
          be effective immediately upon posting on our website. We encourage you to review this
          Privacy Policy periodically to stay informed about how we are protecting your information.
        </p>
        <p>&nbsp;</p>
        <h6>Contact Us</h6>
        <p>
          If you have any questions or concerns about this Privacy Policy or our privacy practices,
          please contact us at 074 207 0749.
        </p>
      </div>
    </MainLayout>
  );
}

export default PrivacyPolicy;
