import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import GuestRoute from './GuestRoute';
import { ROLE } from '../utils/helper';
import Home from '../containers/Home';
import StudentProfile from '../containers/User/StudentProfile';
import TrendingFilterSearch from '../containers/TrendingFilterSearch';
import ExamStart from '../containers/ExamPage/ExamStart';
import ExamPage from '../containers/ExamPage';
import ExamResult from '../containers/ExamResult';
import Registration from '../containers/Registration';
import Faq from '../components/Faq';
import PendingExams from '../containers/PendingExams';
import StartExams from '../containers/StartExam';
import BuyExam from '../containers/BuyExam';
import MobilePay from '../components/BuyExam/MobilePay';
import MyLearnings from '../containers/MyLearnings';
import Community from '../containers/Community';
import About from '../containers/About';
import contact from '../components/Contact/contact';
import sitemap from '../components/Sitemap/sitemap';
import Profile from '../containers/Profile';
import category from '../components/Category/category';
import featured from '../components/Featured/featured';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsService from '../components/TermsService';
import PointBasePayment from '../components/PointPayment';
import MobileBasePay from '../components/BuyPoints/MobilePay';
import FreeCreditPay from '../components/FreeCreditPayment';
import Articles from '../components/CommunityArticles/Articles';

export default function Routes() {
  const params = new URLSearchParams(window.location.search);
  const state = params.get('state');
  function renderComponent(state) {
    if (state === 'CANCELLED') {
      return BuyExam;
    } else if (state === 'CONFIRMED') {
      return BuyExam;
    } else if (state === null) {
      return BuyExam;
    }
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/my-learning/:pageName" component={MyLearnings} />
        <Route exact path="/community" component={Community} />
        <Route exact path="/community-article/:articleId" component={Articles} />
        <Route exact path="/about" component={About} />
        <Route exact path="/sitemap" component={sitemap} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-service" component={TermsService} />
        <Route exact path="/contact" component={contact} />
        <Route exact path="/exam-category" component={category} />
        <Route exact path="/featured-exams" component={featured} />
        <Route exact path="/pending-exams" component={PendingExams} />
        <Route exact path="/buy-exam/:examPaperId" component={renderComponent(state)} />
        <Route exact path="/buy-points-mobile-pay" component={MobileBasePay} />
        <Route exact path="/mobile-pay/:examPaperId" component={MobilePay} />
        <Route exact path="/point-base-payment/:examPaperId" component={PointBasePayment} />
        <Route exact path="/free-credit-pay/:examPaperId" component={FreeCreditPay} />
        <Route exact path="/start-exam" component={StartExams} />
        <Route exact path="/exams" component={TrendingFilterSearch} />
        <GuestRoute exact path="/sign-up" component={Registration} />
        <Route exact path="/profile" component={Profile} />
        <PrivateRoute
          path="/profile-student"
          component={StudentProfile}
          requiredRoles={[ROLE.Student]}
        />
        <PrivateRoute
          exact
          path="/exam/:examPaperId/:examAttemptId"
          component={ExamStart}
          requiredRoles={[ROLE.Student]}
        />
        <PrivateRoute
          path="/exam/:examPaperId/:examAttemptId/questions"
          component={ExamPage}
          requiredRoles={[ROLE.Student]}
        />
        <PrivateRoute
          path="/results/:examPaperId/:examAttemptId"
          component={ExamResult}
          requiredRoles={[ROLE.Student]}
        />
        <PrivateRoute
          path="/completed-exam-results/:examPaperId/:examAttemptId"
          component={ExamResult}
          requiredRoles={[ROLE.Student]}
        />
      </Switch>
    </Router>
  );
}
