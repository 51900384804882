import {
  FECTH_FILTER_SEARCH_DATA,
  FETCH_SUBSCRIPTION_RESULTS,
  FETCH_ALL_SELECTORS,
  FETCH_FILTER_SEARCH_LOAD_MORE,
  CLEAR_SEARCH_DATA,
  FETCH_PENDING_EXAM_PAPER
} from '../utils/type';

const initialState = {
  searchData: {
    content: []
  },
  subscriptionResult: [],
  subscriptionPendingExam: [],
  selectors: {},
  filters: {}
};
const addStatusToSearchResults = (searchData, subscriptionResult) => {
  let newSearchData = searchData || {};
  let newContents = [];
  if (subscriptionResult.length > 0) {
    const examPaperIds = subscriptionResult.map((result) => result.examId);
    if (searchData && searchData.content.length > 0) {
      newContents = searchData.content.map((item) => {
        const isIncludedInUserSubscription = examPaperIds.includes(item.id);
        if (isIncludedInUserSubscription) {
          const examStatus = subscriptionResult.find((exam) => exam.examId === item.id).status;
          item.examStatus = examStatus;
        }
        return item;
      });
      newSearchData.content = newContents;
    }
  }
  return newSearchData;
};

const filterReducer = (state = initialState, action) => {
  let currentSearchContents = [];
  let newSearchData = {};
  let resultsWithStatus = {};
  switch (action.type) {
    case FECTH_FILTER_SEARCH_DATA:
      return {
        ...state,
        searchData: addStatusToSearchResults(action.data, state.subscriptionResult)
      };
    case FETCH_FILTER_SEARCH_LOAD_MORE:
      currentSearchContents = state.searchData.content;
      newSearchData = action.data;
      newSearchData.content = [...newSearchData.content, ...currentSearchContents];
      resultsWithStatus = addStatusToSearchResults(newSearchData, state.subscriptionResult);
      return {
        ...state,
        searchData: resultsWithStatus
      };
    case FETCH_SUBSCRIPTION_RESULTS:
      return {
        ...state,
        subscriptionResult: action.data
      };
    case FETCH_PENDING_EXAM_PAPER:
      return {
        ...state,
        subscriptionPendingExam: action.data
      };
    case FETCH_ALL_SELECTORS:
      return {
        ...state,
        selectors: action.data
      };
    case CLEAR_SEARCH_DATA:
      return {
        ...state,
        searchData: {
          content: []
        }
      };
    default:
      return state;
  }
};

export default filterReducer;
