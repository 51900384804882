import { Box, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getComments, addComments } from '../../actions/articleAction';

function Comments(props) {
  const { articleId } = props;
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const [newComments, setNewComments] = useState('');

  useEffect(() => {
    fetchComments();
  }, []);
  const fetchComments = () => {
    dispatch(
      getComments(articleId, (res) => {
        setComments(res), setNewComments('');
      })
    );
  };
  const handleClick = () => {
    const newText = {
      articleId: articleId,
      comment: newComments
    };
    dispatch(addComments(newText, () => fetchComments()));
  };
  const timeFormatting = (time) => {
    const originalDateTime = time;
    const date = new Date(originalDateTime);
    const formattedDate = date.toISOString().split('T')[0];

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const result = `${formattedDate} ${hours + 5}.${formattedMinutes}${ampm}`;
    return result;
  };
  return (
    <div>
      <Box
        my={4}
        alignItems="center"
        gap={4}
        p={2}
        sx={{
          border: '2px solid grey',
          margin: '10px',
          borderRadius: '10px',
          display: 'block'
        }}
      >
        <Box
          sx={{
            display: 'block',
            maxHeight: '150px',
            overflowY: 'auto',
            padding: '8px'
          }}
        >
          {comments.length >= 1 &&
            comments.map((item, index) => (
              <Box sx={{ marginBottom: '16px' }} key={index}>
                <label style={{ fontWeight: 'bold', display: 'block' }}>{item.userName}</label>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <span
                    style={{
                      backgroundColor: 'gray',
                      padding: '8px',
                      borderRadius: '4px',
                      flexGrow: 1
                    }}
                  >
                    {item.comment}
                  </span>
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'black',
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      marginRight: '8px',
                      marginBottom: '4px'
                    }}
                  >
                    {timeFormatting(item.localDateTime)}
                  </span>
                </Box>
              </Box>
            ))}
        </Box>

        <TextField
          id="filled-multiline-static"
          label="Comments"
          multiline
          rows={2}
          variant="filled"
          fullWidth
          onChange={(val) => setNewComments(val.target.value)}
        />
        <Button variant="contained" sx={{ margin: '5px' }} onClick={() => handleClick()}>
          Post
        </Button>
      </Box>
    </div>
  );
}

export default Comments;
