import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import QuestionAndAnswers from '../../components/ExamPage/QuestionAndAnswers';
import Review from '../../components/Review';
import ExamSubmitConfirm from '../../components/Modal/ConfirmModal';
import { fetchExamPaperById } from '../../actions/examPaperAction';
import {
  getExamAttemptStatus,
  updateAnswer,
  getExamAttemptDetails,
  setExamTimerDuration,
  patchRemainingTime,
  getUTCTime
} from '../../actions/examAction';
import { deleteFile } from '../../actions/examPaperAction';

class ExamPage extends Component {
  constructor() {
    super();
    this.state = {
      examQuestions: {},
      examQuestionType: '',
      currentQuestionNo: 1,
      currentQuestionId: '',
      startedDateTime: null,
      timeOut: false,
      selectedAnswer: [],
      answeredList: [],
      isDoubtFul: false,
      examAttemptId: '',
      allMarked: false,
      isBtnClicked: false,
      isSubmitting: false,
      isSubmitModalOpen: false,
      isRevisitQuestion: true,
      remainingDuration: 0,
      uploadFileURL: [],
      grpQuestion: []
    };
    this.handleNext = this.handleNext.bind(this);
    this.selfCalling = this.selfCalling.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleAnswerChecked = this.handleAnswerChecked.bind(this);
    this.handleDoubtfulChange = this.handleDoubtfulChange.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleReview = this.handleReview.bind(this);
    this.goToQuestion = this.goToQuestion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitConfirm = this.handleSubmitConfirm.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.uploadAnswerFile = this.uploadAnswerFile.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  componentDidMount() {
    const { fetchExamPaperById, getExamAttemptStatus, examPaperId, examAttemptId, history } =
      this.props;
    fetchExamPaperById(examPaperId, () => {
      getExamAttemptStatus(examAttemptId, () => {
        history.push('/pending-exams');
      });
    });
    const { examAttemptDetails } = this.props;
    if (!localStorage.getItem('componentMounted')) {
      window.localStorage.setItem(
        'examDuration',
        JSON.stringify(examAttemptDetails.remainingDurationInMilli)
      );
      const examStartTime = new Date();
      window.localStorage.setItem('examStartTime', examStartTime.toISOString());
      localStorage.setItem('componentMounted', 'true');
    }

    const interval = setInterval(() => {
      this.selfCalling();
      if (location.pathname !== `/exam/${examPaperId}/${examAttemptId}/questions`) {
        clearInterval(interval);
      }
    }, 30000);
  }
  selfCalling() {
    const { patchRemainingTime, examAttemptId } = this.props;
    const examDuration = JSON.parse(window.localStorage.getItem('examDuration'));
    const examStartTime = new Date(window.localStorage.getItem('examStartTime'));
    const currentTime = new Date();
    const remainingDuration = examDuration - (currentTime - examStartTime);
    patchRemainingTime(examAttemptId, remainingDuration);
  }

  componentDidUpdate(preProps) {
    const { currentQuestionNo, selectedAnswer } = this.state;
    const { examPaperDetails, examAttemptStatus, examAttemptDetails, examPaperId, history } =
      this.props;
    if (preProps.examPaperDetails !== examPaperDetails) {
      const questionId = examPaperDetails.questions[currentQuestionNo];
      this.setState({
        examQuestions: examPaperDetails.questions,
        examQuestionType: examPaperDetails.category,
        currentQuestionId: questionId,
        grpQuestion: examPaperDetails.groupQuestions
      });
    }
    if (preProps.examAttemptStatus !== examAttemptStatus) {
      if (!examAttemptStatus.currentQuestionNo && !examAttemptStatus.currentQuestionId) {
        this.setState({
          allMarked: true,
          currentQuestionId: null,
          currentQuestionNo: null
        });
      }
      if (examAttemptStatus.examId !== examPaperId) {
        history.push(`/exam/${examAttemptStatus.examId}/questions`);
      } else {
        const questionId = examPaperDetails.questions[examAttemptStatus.currentQuestionNo];
        this.setState({
          currentQuestionId: questionId,
          currentQuestionNo: examAttemptStatus.currentQuestionNo,
          startedDateTime: examAttemptStatus.startedAt,
          examAttemptId: examAttemptStatus.examAttemptId
        });
      }
    }
    if (preProps.examAttemptDetails !== examAttemptDetails) {
      if (examAttemptDetails.examId !== examPaperId) {
        history.push(`/exam/${examAttemptStatus.examId}/questions`);
      } else {
        this.findExamDuration(examAttemptDetails);
        const questionAttemptMap = examAttemptDetails.questionAttemptMap[currentQuestionNo];
        if (questionAttemptMap && questionAttemptMap.answers) {
          const findCorrectAnswerIndex = questionAttemptMap.answers.findIndex((a) => a === true);
          selectedAnswer.push(findCorrectAnswerIndex + 1);
          this.setState({
            answeredList: questionAttemptMap.answers || [],
            uploadFileURL: questionAttemptMap.essayAnswers || [],
            selectedAnswer,
            isDoubtFul: questionAttemptMap.deferred
          });
        }
        if (questionAttemptMap && questionAttemptMap.essayAnswers) {
          this.setState({
            uploadFileURL: questionAttemptMap.essayAnswers || [],
            isDoubtFul: questionAttemptMap.deferred
          });
        }
      }
    }
  }

  handleNext() {
    let {
      currentQuestionNo,
      examQuestions,
      currentQuestionId,
      answeredList,
      isDoubtFul,
      examAttemptId,
      uploadFileURL
    } = this.state;
    const { updateAnswer, examAttemptDetails, getExamAttemptDetails } = this.props;
    const examDuration = JSON.parse(window.localStorage.getItem('examDuration'));
    const examStartTime = new Date(window.localStorage.getItem('examStartTime'));
    const currentTime = new Date();
    const different = currentTime - examStartTime;
    const attemptId = examAttemptDetails.id || null;
    if (currentQuestionId) {
      this.setState({ isBtnClicked: true });
      const answerData = {
        questionNo: currentQuestionNo,
        remainingDurationInMilli: examDuration - different,
        questionAttempt: {
          questionId: currentQuestionId,
          answers: answeredList,
          essayAnswers: uploadFileURL,
          deferred: isDoubtFul
        },
        action: 'NEXT'
      };
      updateAnswer(answerData, attemptId, () => {
        currentQuestionNo += 1;
        const questionId = examQuestions[currentQuestionNo];
        this.setState(
          {
            currentQuestionNo,
            currentQuestionId: questionId,
            isDoubtFul: false,
            selectedAnswer: [],
            uploadFileURL: [],
            answeredList: [],
            isBtnClicked: false
          },
          () => {
            getExamAttemptDetails(examAttemptId);
          }
        );
      });
    }
  }

  handlePrevious() {
    let {
      currentQuestionNo,
      examQuestions,
      isDoubtFul,
      currentQuestionId,
      answeredList,
      examAttemptId,
      uploadFileURL
    } = this.state;
    const { updateAnswer, examAttemptDetails, getExamAttemptDetails } = this.props;
    const examDuration = JSON.parse(window.localStorage.getItem('examDuration'));
    const examStartTime = new Date(window.localStorage.getItem('examStartTime'));
    const currentTime = new Date();
    const different = currentTime - examStartTime;
    const attemptId = examAttemptDetails.id || null;
    if (currentQuestionId) {
      this.setState({ isBtnClicked: true });
      const answerData = {
        questionNo: currentQuestionNo,
        remainingDurationInMilli: examDuration - different,
        questionAttempt: {
          questionId: currentQuestionId,
          essayAnswers: uploadFileURL,
          answers: answeredList,
          deferred: isDoubtFul
        },
        action: 'PREVIOUS'
      };
      updateAnswer(answerData, attemptId, () => {
        currentQuestionNo -= 1;
        const questionId = examQuestions[currentQuestionNo];
        this.setState(
          {
            currentQuestionNo,
            currentQuestionId: questionId,
            isDoubtFul: false,
            selectedAnswer: [],
            uploadFileURL: [],
            answeredList: [],
            isBtnClicked: false
          },
          () => {
            getExamAttemptDetails(examAttemptId);
          }
        );
      });
    }
  }

  findExamDuration(examAttemptDetails) {
    const { setExamTimerDuration } = this.props;
    this.setState({ remainingDuration: examAttemptDetails.remainingDurationInMilli });
    setExamTimerDuration(examAttemptDetails.remainingDurationInMilli);
    if (!examAttemptDetails.remainingDurationInMilli) {
      this.handleSubmitConfirm();
    }
  }

  handleAnswerChecked(val, answers) {
    let { selectedAnswer } = this.state;
    let answeredList = [];
    console.log('selectedAnswer', selectedAnswer);
    console.log('inside the Function', val);
    if (selectedAnswer[0] == val) {
      selectedAnswer = [];
      answeredList = [];
      console.log('inside if part');
    } else {
      console.log('inside else part');
      selectedAnswer = [];
      selectedAnswer.push(val);
      answeredList = answers.map((answer, i) => (i + 1 === val ? true : false));
    }
    this.setState({ selectedAnswer, answeredList, isDoubtFul: false });
  }
  uploadAnswerFile(testFiles) {
    const { uploadFileURL } = this.state;
    const newPromis = [];
    for (let i = 0; i < testFiles.length; i++) {
      const newPropmisses = new Promise((resolve) => {
        uploadFileURL.push({ answerFile: testFiles[i].urlT });
        this.setState({ isDoubtFul: false });
        resolve();
      });
      newPromis.push(newPropmisses);
    }
    Promise.all(newPromis)
      .then(() => {
        return;
      })
      .catch((e) => {
        console.error('There is a Something wrong Please enter', e);
      });
  }
  deleteImage(index, item) {
    const { uploadFileURL } = this.state;
    const { deleteFile } = this.props;
    deleteFile(item.answerFile);
    uploadFileURL.splice(index, 1);
    this.setState(uploadFileURL);
  }
  handleDoubtfulChange() {
    this.setState({ isDoubtFul: true, selectedAnswer: [], answeredList: [], uploadFileURL: [] });
  }

  handleComplete() {
    let { currentQuestionNo, isDoubtFul, currentQuestionId, answeredList, uploadFileURL } =
      this.state;
    const { updateAnswer, examAttemptDetails } = this.props;
    const examDuration = JSON.parse(window.localStorage.getItem('examDuration'));
    const examStartTime = new Date(window.localStorage.getItem('examStartTime'));
    const currentTime = new Date();
    const different = currentTime - examStartTime;
    const attemptId = examAttemptDetails.id || null;
    if (currentQuestionId) {
      this.setState({ isBtnClicked: true });
      const answerData = {
        questionNo: currentQuestionNo,
        remainingDurationInMilli: examDuration - different,
        questionAttempt: {
          questionId: currentQuestionId,
          essayAnswers: uploadFileURL,
          answers: answeredList,
          deferred: isDoubtFul
        },
        action: 'REVIEW'
      };
      updateAnswer(answerData, attemptId, () => {
        this.setState({
          isDoubtFul: false,
          selectedAnswer: [],
          uploadFileURL: [],
          answeredList: [],
          allMarked: true,
          isBtnClicked: false
        });
      });
    }
  }

  handleReview() {
    let { currentQuestionNo, isDoubtFul, currentQuestionId, answeredList, uploadFileURL } =
      this.state;
    const { updateAnswer, examAttemptDetails } = this.props;
    const examDuration = JSON.parse(window.localStorage.getItem('examDuration'));
    const examStartTime = new Date(window.localStorage.getItem('examStartTime'));
    const currentTime = new Date();
    const different = currentTime - examStartTime;
    const attemptId = examAttemptDetails.id || null;
    if (currentQuestionId) {
      this.setState({ isBtnClicked: true });
      const answerData = {
        questionNo: currentQuestionNo,
        remainingDurationInMilli: examDuration - different,
        questionAttempt: {
          questionId: currentQuestionId,
          essayAnswers: uploadFileURL,
          answers: answeredList,
          deferred: isDoubtFul
        },
        action: 'REVIEW'
      };
      updateAnswer(answerData, attemptId, () => {
        this.setState({
          isDoubtFul: false,
          selectedAnswer: [],
          uploadFileURL: [],
          answeredList: [],
          allMarked: true,
          isBtnClicked: false,
          currentQuestionId: null,
          currentQuestionNo: null
        });
      });
    }
  }

  goToQuestion(e, questionData) {
    e.preventDefault();
    // this.setState({ answeredList: [] });
    const markedAnswer = [];
    if (questionData.questionNo) {
      const findCorrectAnswerIndex = questionData.answers.findIndex((a) => a === true);
      markedAnswer.push(findCorrectAnswerIndex + 1);
      this.handleAnswerChecked(findCorrectAnswerIndex, questionData.answers);
      this.setState({
        allMarked: false,
        currentQuestionId: questionData.questionId,
        currentQuestionNo: questionData.questionNo,
        selectedAnswer: markedAnswer,
        isDoubtFul: questionData.deferred
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    localStorage.clear('examDuration');
    localStorage.clear('componentMounted');
    localStorage.clear('examStartTime');
    this.setState({ isSubmitModalOpen: true });
  }

  handleModalClose(e) {
    e.preventDefault();
    this.setState({ isSubmitModalOpen: false });
  }

  handleSubmitConfirm() {
    const { examAttemptId } = this.state;
    const { history, updateAnswer, examPaperId } = this.props;
    this.setState({ isSubmitting: true });
    const answerData = {
      action: 'SUBMIT'
    };
    updateAnswer(answerData, examAttemptId, () => {
      this.setState({ isSubmitting: false, isSubmitModalOpen: false });
      history.push(`/results/${examPaperId}/${examAttemptId}`);
    });
  }
  render() {
    const {
      selectedAnswer,
      allMarked,
      isSubmitModalOpen,
      isSubmitting,
      examAttemptId,
      remainingDuration,
      examQuestionType
    } = this.state;
    const { examPaperDetails } = this.props;

    return (
      <Fragment>
        {allMarked ? (
          <Review
            goToQuestion={this.goToQuestion}
            handleSubmit={this.handleSubmit}
            examAttemptId={examAttemptId}
            noOfQuestions={examPaperDetails.noOfQuestions}
            examQuestionType={examQuestionType}
          />
        ) : (
          <QuestionAndAnswers
            {...this.state}
            handleNext={this.handleNext}
            handlePrevious={this.handlePrevious}
            handleComplete={this.handleComplete}
            handleReview={this.handleReview}
            uploadAnswerFile={this.uploadAnswerFile}
            remainingDuration={remainingDuration}
            handleAnswerChecked={this.handleAnswerChecked}
            selectedAnswer={selectedAnswer}
            handleDoubtfulChange={this.handleDoubtfulChange}
            noOfQuestions={examPaperDetails.noOfQuestions}
            deleteImage={this.deleteImage}
            examPaperDetails={examPaperDetails}
          />
        )}
        <ExamSubmitConfirm
          open={isSubmitModalOpen}
          isSubmitting={isSubmitting}
          handleClose={this.handleModalClose}
          handleSubmit={this.handleSubmitConfirm}
          msg="Are you sure want to submit the questions ?"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  examPaperDetails: state.examPaper.examPaperDetails,
  examAttemptDetails: state.exam.examAttemptDetails,
  examAttemptStatus: state.exam.examAttemptStatus
});

const mapDispatchToProps = {
  fetchExamPaperById,
  setExamTimerDuration,
  getExamAttemptStatus,
  getExamAttemptDetails,
  patchRemainingTime,
  getUTCTime,
  updateAnswer,
  deleteFile
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamPage);
