export const FECTH_FILTER_SEARCH_DATA = 'FECTH_FILTER_SEARCH_DATA';
export const FETCH_FILTER_SEARCH_LOAD_MORE = 'FETCH_FILTER_SEARCH_LOAD_MORE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const FETCH_EXAMS_PAPERS = 'FETCH_EXAMS_PAPERS';
export const FETCH_EXAM_PAPER_BY_ID = 'FETCH_EXAM_PAPER_BY_ID';
export const FETCH_EXAM_QUESTION_BY_ID = 'FETCH_EXAM_QUESTION_BY_ID';
export const FETCH_ALL_TEACHERS = 'FETCH_ALL_TEACHERS';
export const FETCH_TEACHER_EXAM_PAPERS = 'FETCH_TEACHER_EXAM_PAPERS';
export const FETCH_SUBSCRIPTION_RESULTS = 'FETCH_SUBSCRIPTION_RESULTS';
export const FETCH_ALL_QUESTIONS = 'FETCH_ALL_QUESTIONS';

export const GET_EXAM_ATTEMPT = 'GET_EXAM_ATTEMPT';
export const GET_ATTEMPT_STATUS = 'GET_ATTEMPT_STATUS';
export const GET_EXAM_ATTEMPT_RESULT = 'GET_EXAM_ATTEMPT_RESULT';

export const SET_TIMER_DURATION = 'SET_TIMER_DURATION';

export const FETCH_ALL_SELECTORS = 'FETCH_ALL_SELECTORS';
export const FETCH_CASCADE_FILTER = 'FETCH_CASCADE_FILTER';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
export const GET_PAYMENT_LINK = 'GET_PAYMENT_LINK';
export const FETCH_PENDING_EXAM_PAPER = 'FETCH_PENDING_EXAM_PAPER';

export const FETCH_POPULER_EXAMS = 'FETCH_POPULER_EXAMS';
export const FETCH_PAST_PAPERS = 'FETCH_PAST_PAPERS';
export const FETCH_FAQ = 'FETCH_FAQ';

export const FETCH_PROFILE_DETAILS = 'FETCH_PROFILE_DETAILS';

export const FETCH_BALANCE_POINT = 'FETCH_BALANCE_POINT';
