import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainLayout from '../../layouts/MainLayout';
import { getSubscriptionResults } from '../../actions/filterAction';
import keycloakService from '../../keycloakService';
import SubScriptionResults from '../../components/SubScriptionResults';

class MyLearnings extends Component {
  constructor() {
    super();
    this.state = {
      completedExamList: [],
      pendingExamsList: [],
      correctionPendingList: [],
      fetchedPaperList: [],
      defaultPage: '',
      local: ''
    };
    this.handleHeading = this.handleHeading.bind(this);
  }
  componentDidMount() {
    const { getSubscriptionResults, match, selectedLanguage } = this.props;

    const { pageName } = match.params;

    localStorage.setItem('NavName', 'MyLearning');
    const { isLoggedIn } = keycloakService;
    if (isLoggedIn()) {
      if (pageName == 'pending-exampage') {
        const statuses = 'PENDING,STARTED,REVIEW';
        getSubscriptionResults(statuses);
        this.setState({ defaultPage: pageName });
      } else if (pageName == 'complete-exampage') {
        getSubscriptionResults('CORRECTED');
        this.setState({ defaultPage: pageName });
      } else if (pageName == 'correction-exampage') {
        getSubscriptionResults('PENDING_CORRECTION');
        this.setState({ defaultPage: pageName });
      }
    }
    this.setState({ local: selectedLanguage });
  }

  componentDidUpdate(preProps) {
    const { subscriptionResultPending, selectedLanguage } = this.props;
    if (subscriptionResultPending !== preProps.subscriptionResultPending) {
      this.setState({ fetchedPaperList: subscriptionResultPending });
    }
    if (selectedLanguage !== preProps.selectedLanguage) {
      this.setState({ local: selectedLanguage });
    }
  }
  handleHeading(heading) {
    const { local } = this.state;
    if (heading == 'heading') {
      if (local == 'sinhala') {
        return 'මගේ ඉගෙනීම';
      } else if (local == 'tamil') {
        return 'என் கற்றல்';
      } else {
        return 'My Learning';
      }
    }
    if (heading == 'pending') {
      if (local == 'sinhala') {
        return 'පොරොත්තුවෙන්';
      } else if (local == 'tamil') {
        return 'நிலுவையில் உள்ளது';
      } else {
        return 'Pending';
      }
    }
    if (heading == 'completed') {
      if (local == 'sinhala') {
        return 'සම්පූර්ණ කර ඇත';
      } else if (local == 'tamil') {
        return 'நிறைவு';
      } else {
        return 'Completed';
      }
    }
    if (heading == 'correction_Pending') {
      if (local == 'sinhala') {
        return 'නිවැරදි කිරීම පොරොත්තුවෙන්';
      } else if (local == 'tamil') {
        return 'திருத்தம் நிலுவையில் உள்ளது';
      } else {
        return 'Correction Pending';
      }
    }
  }
  render() {
    const { defaultPage, fetchedPaperList } = this.state;
    return (
      <MainLayout>
        <div className="pt-5 pt-md-0">
          <h1>{this.handleHeading('heading')}</h1>
          <div className="d-flex py-2">
            <a
              href={`/my-learning/${'pending-exampage'}`}
              className={`btn px-4 mr-3 ${
                defaultPage == 'pending-exampage' ? 'btn-primary' : 'btn-secondary'
              }`}
            >
              {this.handleHeading('pending')}
            </a>
            <a
              href={`/my-learning/${'complete-exampage'}`}
              className={`btn px-4 mr-3 ${
                defaultPage == 'complete-exampage' ? 'btn-primary' : 'btn-secondary'
              }`}
            >
              {this.handleHeading('completed')}
            </a>
            <a
              href={`/my-learning/${'correction-exampage'}`}
              className={`btn px-4 mr-3 ${
                defaultPage == 'correction-exampage' ? 'btn-primary' : 'btn-secondary'
              }`}
            >
              {this.handleHeading('correction_Pending')}
            </a>
          </div>
          {defaultPage == 'pending-exampage' ? (
            <SubScriptionResults examList={fetchedPaperList} title="Pending Exams" />
          ) : defaultPage == 'complete-exampage' ? (
            <SubScriptionResults examList={fetchedPaperList} title="Completed Exams" />
          ) : (
            <SubScriptionResults examList={fetchedPaperList} title="Correction Pending Exams" />
          )}
        </div>
      </MainLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  subscriptionResultPending: state.filter.subscriptionResult,
  selectedLanguage: state.language.selectedLanguage
});

const mapDispatchToProps = {
  getSubscriptionResults
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLearnings);
