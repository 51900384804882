import React, { useEffect, useState } from 'react';
import Annotation from 'react-image-annotation';
import { RectangleSelector } from 'react-image-annotation/lib/selectors';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { Button, Grid, makeStyles } from '@material-ui/core';
const useStyle = makeStyles(() => ({
  imageNav: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  marks: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px'
  }
}));
function DesignAnnotation(props) {
  const { uploadFileURL, currentQuestionId } = props;
  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({});
  const [currentImageNo, setCurrentQuestionNo] = useState(0);
  const [img, setImg] = useState('');
  const noOfImages = uploadFileURL.length;
  const classes = useStyle();
  useEffect(() => {
    setCurrentQuestionNo(0);
  }, [currentQuestionId]);
  useEffect(() => {
    setImg(uploadFileURL[currentImageNo]?.answerFile || uploadFileURL[0]?.answerFile);
    setAnnotations(uploadFileURL[currentImageNo]?.fileAnnotations || []);
  }, [uploadFileURL]);

  const nextImage = () => {
    setImg(uploadFileURL[currentImageNo + 1].answerFile);
    setCurrentQuestionNo(currentImageNo + 1);
    setAnnotation({});
    setAnnotations(uploadFileURL[currentImageNo + 1]?.fileAnnotations || []);
  };
  const previousImage = () => {
    setImg(uploadFileURL[currentImageNo - 1].answerFile);
    setCurrentQuestionNo(currentImageNo - 1);
    setAnnotation({});
    setAnnotations(uploadFileURL[currentImageNo - 1]?.fileAnnotations || []);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={1} sm={1} className={classes.imageNav}>
        <Button
          disabled={currentImageNo == 0}
          onClick={previousImage}
          variant="outlined"
          color="primary"
        >
          <NavigateBefore />
        </Button>
      </Grid>
      <Grid item xs={10} sm={10}>
        <Annotation
          src={img}
          alt=""
          annotations={annotations}
          type={RectangleSelector.type}
          value={annotation}
        />
      </Grid>
      <Grid item xs={1} sm={1} className={classes.imageNav}>
        <Button
          disabled={noOfImages == currentImageNo + 1 || img.length <= 0}
          onClick={nextImage}
          variant="outlined"
          color="primary"
        >
          <NavigateNext />
        </Button>
      </Grid>
    </Grid>
  );
}

export default DesignAnnotation;
