import axios from 'axios';
import {
  FECTH_FILTER_SEARCH_DATA,
  FETCH_SUBSCRIPTION_RESULTS,
  FETCH_ALL_SELECTORS,
  FETCH_FILTER_SEARCH_LOAD_MORE,
  CLEAR_SEARCH_DATA,
  FETCH_PENDING_EXAM_PAPER,
  FETCH_CASCADE_FILTER
} from '../utils/type';
import { API_URL, appendQueryParams, authHeader } from '../utils/helper';
import keycloakService from '../keycloakService';

export const getValidToken = async () => {
  const isLoggedIn = keycloakService.isLoggedIn();
  if (isLoggedIn) {
    await keycloakService.updateToken();
    const token = keycloakService.getToken();
    return token;
  }
  return keycloakService.login();
};

export const fetchAllSelectors = () => {
  return async (dispatch) => {
    axios
      .get(`${API_URL}/selector/all`)
      .then((res) => {
        dispatch({
          type: FETCH_ALL_SELECTORS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('something went wrong in getSelecterAll call', e);
      });
  };
};
export const cascadeFilter = (filter) => {
  return async (dispatch) => {
    axios
      .get(`${API_URL}/selector/get-by-parent-id/${filter}`)
      .then((res) => {
        dispatch({
          type: FETCH_CASCADE_FILTER,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('something went wrong in getSelecterAll call', e);
      });
  };
};

export const fetchSearchResult = (searchData, onsuccess = () => {}, isLoadMore = false) => {
  return async (dispatch) => {
    let page = 0;
    let pageSize = 3;
    if (searchData.page) {
      page = searchData.page;
    }
    if (searchData.pageSize) {
      pageSize = searchData.pageSize;
    }

    const baseURL = `${API_URL}/exam/paper/search?page=${page}&size=${pageSize}&sort=title,desc&sort=description,desc`;
    const appendedURL = appendQueryParams(baseURL, searchData.query);
    axios
      .get(appendedURL)
      .then((res) => {
        if (isLoadMore) {
          dispatch({
            type: FETCH_FILTER_SEARCH_LOAD_MORE,
            data: res.data
          });
        } else {
          dispatch({
            type: FECTH_FILTER_SEARCH_DATA,
            data: res.data
          });
        }
        onsuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getSubscriptionResults = (statuses = '') => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/subscriptions?statuses=${statuses}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_SUBSCRIPTION_RESULTS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const getPendingExamResults = (statuses = '') => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/subscriptions?statuses=${statuses}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_PENDING_EXAM_PAPER,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const clearSearchData = () => {
  return {
    type: CLEAR_SEARCH_DATA
  };
};
