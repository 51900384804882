import React, { Fragment, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import ReadOnly from '../RichTextEditor/ReadOnly';
import { fetchExamQuestionById } from '../../actions/examPaperAction';
import AnswerList from './AnswerList';
import FilesUpload from './FilesUpload';
import { useTranslation } from 'react-i18next';

function findAnswers(answers) {
  const answersData = [];
  if (answers && answers.length) {
    answers.forEach((data, index) => {
      const initialValue = JSON.parse(data.answer);
      answersData.push({ initialValue, id: index });
    });
  }
  return answersData;
}

const scrole = {
  overflowY: 'auto'
};
const questionInitialBlock = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
const QuestionAndAnswers = (props) => {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const [initialValue, setInitialValue] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ]);
  const [answers, setAnswers] = useState([]);
  const {
    currentQuestionId,
    currentQuestionNo,
    handleNext,
    handlePrevious,
    remainingDuration,
    handleAnswerChecked,
    selectedAnswer,
    isDoubtFul,
    handleDoubtfulChange,
    noOfQuestions,
    isBtnClicked,
    isRevisitQuestion,
    handleReview,
    handleComplete,
    examQuestionType,
    uploadAnswerFile,
    uploadFileURL,
    deleteImage,
    examPaperDetails
  } = props;
  const dispatch = useDispatch();
  const { questionDetails } = useSelector((state) => state.examPaper);
  useEffect(() => {
    if (currentQuestionId) {
      dispatch(fetchExamQuestionById(currentQuestionId));
    }
  }, [currentQuestionId]);

  useEffect(() => {
    if (questionDetails.question) {
      setInitialValue(JSON.parse(questionDetails.question));
    }
    if (questionDetails.answers) {
      const allAnswers = findAnswers(questionDetails.answers);
      setAnswers(allAnswers);
    }
  }, [questionDetails]);
  const handleGrpQuestion = (index) => {
    let questionObj = { question: questionInitialBlock, show: 'none' };
    const grpQuestion = examPaperDetails.groupQuestions;
    if (grpQuestion != null) {
      for (let i = 0; i < grpQuestion.length; i++) {
        const item = grpQuestion[i];
        if (item.startQuestion <= index && index <= item.endQuestion) {
          questionObj = {
            question: JSON.parse(item.grpQuestion),
            show: ''
          };
          break;
        } else {
          questionObj = { question: questionInitialBlock, show: 'none' };
        }
      }
    }
    return questionObj;
  };
  return (
    <Fragment>
      <Fragment>
        <div>
          <Card style={{ backgroundColor: isDoubtFul ? '#D4F1F4' : '' }} className="ex-card">
            <CardContent>
              <h5 className="text-black">
                {t('question')} - {currentQuestionNo}
              </h5>
              {examPaperDetails.groupQuestions != null && (
                <div
                  style={{ display: handleGrpQuestion(currentQuestionNo)?.show, margin: '10px' }}
                >
                  <p>
                    <strong>Group Question</strong>
                  </p>
                  <Grid item xs={12} sm={12}>
                    <ReadOnly initialValue={handleGrpQuestion(currentQuestionNo).question} />
                  </Grid>
                </div>
              )}
              <ReadOnly initialValue={initialValue} />
              {examQuestionType == 'MCQ' ? (
                <>
                  <div style={scrole} data-spy="scroll">
                    <AnswerList
                      answers={answers}
                      handleChange={handleAnswerChecked}
                      selectedAnswer={selectedAnswer}
                      chaneColor={isDoubtFul}
                    />
                  </div>
                </>
              ) : (
                <FilesUpload
                  uploadAnswerFile={uploadAnswerFile}
                  uploadFileURL={uploadFileURL}
                  deleteImage={deleteImage}
                />
              )}
            </CardContent>
          </Card>
        </div>
        <Grid style={{ marginTop: 30, marginBottom: 30 }} container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  edge="end"
                  checked={isDoubtFul}
                  onChange={handleDoubtfulChange}
                  color="primary"
                  style={{ margin: 10 }}
                />
              }
              label={t('doubtFul_question')}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              onClick={handlePrevious}
              disabled={currentQuestionNo === 1 || !remainingDuration || isBtnClicked}
              style={{ float: 'left' }}
              variant="outlined"
              color="primary"
              className="btn btn-secondary px-4"
            >
              <ArrowBackIcon /> {t('previous')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            {noOfQuestions === currentQuestionNo ? (
              <Button
                disabled={!remainingDuration || isBtnClicked}
                onClick={handleComplete}
                style={{ float: 'right' }}
                variant="primary"
                className="btn btn-primary px-4 text-white"
                color="primary"
              >
                {t('review')}
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                disabled={!remainingDuration || isBtnClicked}
                style={{ float: 'right' }}
                variant="outlined"
                color="primary"
                className="btn btn-secondary px-4"
              >
                {t('next')} <ArrowForwardIcon />
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isRevisitQuestion ? (
              <Button
                onClick={handleReview}
                disabled={currentQuestionNo == noOfQuestions}
                style={{ float: 'left' }}
                variant="primary"
                className="btn btn-primary px-4 text-white"
                color="primary"
              >
                {t('review')}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Fragment>
    </Fragment>
  );
};

export default QuestionAndAnswers;
