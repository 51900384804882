import { FETCH_FAQ } from '../utils/type';
import axios from 'axios';
import { API_URL } from '../utils/helper';

export const fetchFAQ = (language) => {
  return async (dispatch) => {
    axios
      .get(`${API_URL}/faq/all?lang=${language}`)
      .then((res) => {
        dispatch({
          type: FETCH_FAQ,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
