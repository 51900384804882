import React, { useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const index = () => {
  localStorage.setItem('NavName', 'About');
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <MainLayout>
      <div className="pt-5 pt-md-0">
        <h1>{t('about_team')}</h1>
        <div>
          <p>{t('about_para1')}</p>
          <p>&nbsp;</p>
          <p>{t('about_para2')}</p>
        </div>
      </div>
    </MainLayout>
  );
};
export default index;
