import { FETCH_CASCADE_FILTER } from '../utils/type';

const initialState = {
  cascadeFilter: {}
};

const cascadeFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CASCADE_FILTER:
      return {
        ...state,
        cascadeFilter: action.data
      };
    default:
      return state;
  }
};

export default cascadeFilterReducer;
