import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Progress from '../Loader/Progress';
import { Link } from '@material-ui/core';
import ReadOnly from '../RichTextEditor/ReadOnly';
import { Box } from '@material-ui/core';
import BgImg from '../../assets/images/chemistry-bg.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Rating from '@mui/material/Rating';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    marginTop: -10,
    marginBottom: 35
  },
  btnGird: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  completedStatus: {
    marginLeft: 40,
    marginRight: 40,
    color: 'green',
    fontWeight: 500
  },
  change: {
    color: '#ffa726',
    backgroundColor: '#ffa726'
  }
}));
const handleTimer = (timer) => {
  const hr = Math.floor(timer / 60);
  const min = timer % 60;
  return <span>{hr === 0 ? `${min}min` : `${hr}hr ${min}min`}</span>;
};

const ResultsGrid = (props) => {
  const { results, handleLoadMore, hasMoreItems, isDataLoading } = props;
  const history = useHistory();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  const getDescription = (desc) => {
    let parsedDescription;
    let isError = false;

    try {
      parsedDescription = JSON.parse(desc);
    } catch (error) {
      isError = true;
      return desc;
    }

    if (!isError && typeof parsedDescription == 'number') {
      return parsedDescription;
    } else {
      let readonly = <ReadOnly initialValue={parsedDescription} />;
      return readonly;
    }
  };
  const handleBuy = (id) => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Buy Button'
    });
    window.scrollTo(0, 0);
    history.push(`/buy-exam/${id}`);
  };
  const displayReults = (examsResults) => {
    if (examsResults && examsResults.length) {
      return examsResults.map((result) => (
        <div className="col-lg-4" key={result.id}>
          <div className="ex-card in--my-learning">
            <div className="ex-card-img">
              <img className="img-bg" src={BgImg} />
            </div>
            <a>
              <h3 className="ex-title">{result.title}</h3>
            </a>
            <div className="ex-tag-block d-flex pb-4">
              <span className="tag-item mr-3 fw-800" style={{ backgroundColor: '#E2F6FF' }}>
                {result.grade} {result.subject}
              </span>
              <span className="duration-item d-flex align-items-center">
                <span className="clock-ic mr-1 mt--5">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5 4V8.5L11.5 10M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
                      stroke="#00394f"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className="duration-text fw-800">{handleTimer(result.durationInMinute)}</span>
              </span>
            </div>
            <p className="ex-info">{getDescription(result.description)}</p>
            {result.examRating != null && (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <span className="fw-600">Difficulty</span>
                  </Grid>
                  <Grid item xs={3}>
                    <span>
                      <Rating
                        name="disabled"
                        value={result.examRating.difficulty}
                        precision={0.5}
                        readOnly
                      />
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <span className="fw-600">Uniqueness</span>
                  </Grid>
                  <Grid item xs={3}>
                    <span>
                      <Rating
                        name="disabled"
                        value={result.examRating.uniqueness}
                        precision={0.5}
                        readOnly
                      />
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <span className="fw-600">Timing</span>
                  </Grid>
                  <Grid item xs={3}>
                    <span>
                      <Rating
                        name="disabled"
                        value={result.examRating.timing}
                        precision={0.5}
                        readOnly
                      />
                    </span>
                  </Grid>
                </Grid>
              </div>
            )}
            <div className="author-block d-flex align-items-center mt-4">
              <img className="author-img mr-2" src={BgImg} />
              <span className="author-name fw-600">{result.teacherName}</span>
            </div>
            <div className="btm-ex-block d-flex mt-4">
              <div className="ex-price fw-800">Rs.{result.sellingPrice}.00</div>
              <div className="btn-ex-buy-now ml-auto">
                <Link
                  onClick={() => handleBuy(result.id)}
                  style={{ color: 'white', textTransform: 'none' }}
                >
                  <button className="btn btn-primary px-5 fw-800">{t('buy_now')}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };
  const showResultsMessage = (results) => {
    if (results.content.length === 0) {
      return t('exampage_filter_detail');
    } else {
      if (language === 'en') {
        return `Showing 1-${results.content.length} of ${results.totalElements} results`;
      } else if (language === 'tamil') {
        return `${results.totalElements} முடிவுகளில் 1-${results.content.length} ஐக் காட்டுகிறது`;
      } else {
        return `ප්‍රතිඵල ${results.totalElements} න් 1-${results.content.length} ක් පෙන්වමින්`; // Default message for unknown languages
      }
    }
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Progress isLoading={isDataLoading} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div>
            <div className="mb-3">{showResultsMessage(results)}</div>
            <div className="row">{displayReults(results.content)}</div>
          </div>
        </Grid>
        <Grid className={classes.btnGird} item xs={12} sm={12}>
          {hasMoreItems ? (
            <button
              onClick={handleLoadMore}
              className="btn btn-secondary px-5 fw-800 py-2 px-4 mt-5"
            >
              {t('load')}
            </button>
          ) : null}
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          height: 100
        }}
      />
    </div>
  );
};

export default ResultsGrid;
