import React from 'react';
import MainLayout from '../../layouts/MainLayout';

function category() {
  return (
    <MainLayout>
      <h1>Exam categories offered by ExamAnytime</h1>
      <div>
        <p>&nbsp;</p>
        <h6>AL</h6>
        <p>
          Offering resources for both multiple-choice questions (MCQs) and part 2 sections adds
          depth to our platform&apos;s usefulness. By providing comprehensive coverage of all exam
          sections, including essay or longer-answer components, your website ensures that students
          are well-prepared for every aspect of the GCE A/L exams. This holistic approach to exam
          preparation can significantly improve students&apos; overall performance and increase
          their chances of success, whether they&apos;re aiming for local or foreign universities.
        </p>
        <p>&nbsp;</p>
        <h6>OL</h6>
        <p>
          Focusing specifically on O/L exams, our website plays a crucial role in preparing students
          for this milestone in their academic journey. By offering comprehensive study materials
          and tools to alleviate exam anxiety, it empowers students to approach their exams with
          confidence. Additionally, the detailed analysis of students&apos; performance provides
          valuable insights for improvement, ensuring that they not only pass their O/L exams but
          also excel in them. This support is essential as passing O/L exams is often a prerequisite
          for both further studies and entry into the workforce, setting the foundation for future
          success.
        </p>
        <p>&nbsp;</p>
        <h6>Grade 5 Scholarship</h6>
        <p>
          Our platform seems to be incredibly beneficial for students in Sri Lanka, especially
          considering the significance of the scholarship exam in securing better school admissions.
          By offering access to nationwide exam papers and simplifying the final exam preparation
          process, our service not only enhances students&apos; chances of passing the scholarship
          exam but also ensures they are well-prepared for it. This comprehensive approach not only
          reduces the stress associated with such competitive exams but also increases
          students&apos; confidence in their abilities to succeed.
        </p>
        <p>&nbsp;</p>
        <h6>Others</h6>
        <p>
          Expanding our website&apos;s support beyond specific exams to encompass a wide range of
          assessments is a commendable approach, providing flexibility and utility for both students
          and teachers. By offering analytical tools for both students and teachers, our platform
          becomes a versatile resource that fosters a collaborative learning environment. Teachers
          can gain valuable insights into their students&apos; performance and tailor their teaching
          strategies accordingly, while students benefit from personalized feedback and targeted
          areas for improvement. This dual analytical capability enhances the overall educational
          experience and contributes to the academic success of both students and educators alike.
        </p>
      </div>
    </MainLayout>
  );
}

export default category;
