import { Card, CardContent, Grid } from '@material-ui/core'; //Box,
import React, { Fragment, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { uploadFiles } from '../../actions/examPaperAction';
import { useDispatch } from 'react-redux';
import Compressor from 'compressorjs';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function FilesUpload(props) {
  const { uploadAnswerFile, uploadFileURL, deleteImage } = props;
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const [testFiles, setTestFiles] = useState([]);
  const dispatch = useDispatch();
  const [msg, setMsg] = useState(null);
  const [FailierMsg, setFailierMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const clearFailerMsg = () => {
    setTimeout(() => {
      setFailierMsg('');
    }, 3000);
  };
  const clearSuccessMsg = () => {
    setTimeout(() => {
      setSuccessMsg('');
    }, 3000);
  };
  const handleFileUpload = () => {
    if (testFiles.length >= 1) {
      setMsg('');
      setSuccessMsg(t('uploaded_successful'));
      setTestFiles([]);
      uploadAnswerFile(testFiles);
      clearSuccessMsg();
    } else {
      setFailierMsg(t('please_upload'));
      clearFailerMsg();
    }
  };
  const handleCompressImage = (e) => {
    const file = e.target.files;
    const compressPromises = [];
    for (let i = 0; i < file.length; i++) {
      if (file[i] && file[i].size > 1024 * 1024 * 2) {
        setMsg(t('please_upload_less_than_2MB'));
        continue;
      }
      setMsg(t('uploading'));

      const compressPromise = new Promise((resolve, reject) => {
        new Compressor(file[i], {
          quality: 0.8,
          success(result) {
            const formData = new FormData();
            formData.append('file', result);
            dispatch(
              uploadFiles(formData, (url) => {
                setTestFiles((prevTestFiles) => [...prevTestFiles, { fileT: result, urlT: url }]);
                resolve();
              })
            );
          },
          error(err) {
            console.error(err.message);
            reject(err);
          }
        });
      });

      compressPromises.push(compressPromise);
    }
    Promise.all(compressPromises)
      .then(() => {
        console.log('All files processed and dispatched successfully');
        setMsg(t('please_Click_upload_button'));
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });
  };

  return (
    <Fragment>
      <h4>{t('Please_upload_answers')}</h4>
      {FailierMsg ? <p style={{ color: 'red', fontSize: '20px' }}>{FailierMsg}</p> : null}
      {successMsg ? <p style={{ color: 'green', fontSize: '20px' }}>{successMsg}</p> : null}
      {msg && <span>{msg}</span>}
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={8}>
              <input
                type="file"
                id="fileInput"
                onChange={handleCompressImage}
                multiple
                accept="image/*"
                style={{ display: 'none' }}
              />
              <label
                htmlFor="fileInput"
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  border: '1px solid black',
                  padding: '3px'
                }}
              >
                {t('choose_file')}
              </label>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button onClick={handleFileUpload}>{t('upload_files')}</Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ul>
                {testFiles.length >= 1 &&
                  testFiles.map((file, index) => <li key={index}>{file.fileT.name}</li>)}
                <PhotoProvider>
                  <div className="foo">
                    {uploadFileURL.length >= 1 &&
                      uploadFileURL.map((item, index) => (
                        <div
                          style={{
                            display: 'inline-grid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            backgroundColor: 'gray',
                            padding: '3px',
                            margin: '3px'
                          }}
                          key={index}
                        >
                          <PhotoView key={index} src={item.answerFile}>
                            <img
                              src={item.answerFile}
                              alt=""
                              style={{ width: '50px', height: '50px' }}
                            />
                          </PhotoView>
                          <DeleteIcon
                            style={{ marginLeft: '10px' }}
                            onClick={() => deleteImage(index, item)}
                          />
                        </div>
                      ))}
                  </div>
                </PhotoProvider>
              </ul>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}
