import React, { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Card from '@material-ui/core/Card';

export class StartExams extends Component {
  render() {
    return (
      <div>
        <MainLayout>
          <Card>
            <h1>This Portal is not yet Open</h1>
          </Card>
        </MainLayout>
      </div>
    );
  }
}

export default StartExams;
