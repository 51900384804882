import axios from 'axios';
import { API_URL, authHeader } from '../utils/helper';
import { getValidToken } from './filterAction';
import {
  GET_EXAM_ATTEMPT,
  GET_ATTEMPT_STATUS,
  SET_TIMER_DURATION,
  GET_EXAM_ATTEMPT_RESULT,
  FETCH_POPULER_EXAMS,
  FETCH_PAST_PAPERS
} from '../utils/type';

export const getExamAttemptStatus = (examAttemptId, onFailure = () => {}) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/attempt/status/${examAttemptId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: GET_ATTEMPT_STATUS,
          data: res.data
        });
        dispatch(getExamAttemptDetails(res.data.examAttemptId));
      })
      .catch((e) => {
        onFailure();
        console.error('there is something went wrong', e);
      });
  };
};
export const patchRemainingTime = (examAttemptId, remainingDuration, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(
        `${API_URL}/exam/attempt/remaining/${examAttemptId}?remainingDuration=${remainingDuration}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const getExamAttemptDetails = (attemptId, onSuccess = () => {}) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/attempt/${attemptId}`, authHeader(token))
      .then((res) => {
        dispatch({
          type: GET_EXAM_ATTEMPT,
          data: res.data
        });
        onSuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const createExamAttempt = (examData, onSuccess, onFailure) => {
  return async () => {
    const token = await getValidToken();
    axios
      .put(`${API_URL}/exam/attempt?examId=${examData.examId}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          onFailure();
        }
        console.error('there is something went wrong', e);
      });
  };
};

export const updateAnswer = (answerData, attemptId, onSuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(`${API_URL}/exam/attempt/${attemptId}`, answerData, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getExamAttemptResults = (attemptId, onSuccess = () => {}) => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/exam/attempt/${attemptId}/result`, authHeader(token))
      .then((res) => {
        dispatch({
          type: GET_EXAM_ATTEMPT_RESULT,
          data: res.data
        });
        onSuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const getUTCTime = (onsuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/utc-time-now`, authHeader(token))
      .then((res) => {
        onsuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const setExamTimerDuration = (duration) => {
  return {
    type: SET_TIMER_DURATION,
    data: duration
  };
};

export const fetchPopulerExams = () => {
  return async (dispatch) => {
    axios
      .get(`${API_URL}/exam/top/by-grade`)
      .then((res) => {
        dispatch({
          type: FETCH_POPULER_EXAMS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};

export const fetchPastPapers = () => {
  return async (dispatch) => {
    axios
      .get(`${API_URL}/exam/past-paper/types`)
      .then((res) => {
        dispatch({
          type: FETCH_PAST_PAPERS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something went wrong in fetchPastPapers', e);
      });
  };
};
export const postStudentFeedback = (examAttemptId, feedback, onSuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/exam/attempt/feedback/${examAttemptId}`, feedback, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
