import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import keycloakService from '../../keycloakService';

const useStyles = makeStyles(() => ({
  successMsg: {
    color: 'green',
    fontSize: '20px',
    fontWeight: 'bold'
  }
}));

const Profile = (props) => {
  const classes = useStyles();
  const userDetails = keycloakService.getUserDetails();
  const { handleUpdatePassword, isUpdatePassowrdBtnClicked, updatePasswordSuccessMsg } = props;
  return (
    <Fragment>
      <div>
        <h3>User Information</h3>
        <ul>
          <li>Full Name: {userDetails.name || ''}</li>
          <li>User Name: {userDetails.preferred_username || ''}</li>
          <li>Email: {userDetails.email || ''}</li>
        </ul>
        {updatePasswordSuccessMsg ? (
          <p className={classes.successMsg}>{updatePasswordSuccessMsg}</p>
        ) : null}

        {!updatePasswordSuccessMsg ? (
          <Button
            disabled={isUpdatePassowrdBtnClicked}
            variant="contained"
            onClick={handleUpdatePassword}
            color="primary"
          >
            Update Password
          </Button>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Profile;
