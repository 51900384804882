import axios from 'axios';
import { API_URL, authHeader } from '../utils/helper';
import { getValidToken } from './filterAction';
import { FETCH_PROFILE_DETAILS } from '../utils/type';

export const register = (userData, onsuccess, onFailure) => {
  return async () => {
    axios
      .post(`${API_URL}/user/register`, userData, authHeader())
      .then((res) => {
        onsuccess(res.data);
      })
      .catch((e) => {
        onFailure(e);
        console.error('there is something went wrong', e);
      });
  };
};

export const updatePassword = (onsuccess) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(`${API_URL}/user/update-password`, {}, authHeader(token))
      .then(() => {
        onsuccess();
      })
      .catch((e) => {
        console.error('there is something went wrong', e);
      });
  };
};
export const fetchProfileDetail = () => {
  return async (dispatch) => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/profile`, authHeader(token))
      .then((res) => {
        dispatch({
          type: FETCH_PROFILE_DETAILS,
          data: res.data
        });
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};

export const patchProfileDetail = (profile, onsuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(`${API_URL}/user/profile`, profile, authHeader(token))
      .then((res) => {
        onsuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong', e);
      });
  };
};

export const deleteUserDetails = (onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .delete(`${API_URL}/user/delete`, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something wrong when you delete user', e);
      });
  };
};

export const getJoyRideStatus = (onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .get(`${API_URL}/user/get-joyride-status`, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is something error in joyride status fetching', e);
      });
  };
};
export const joyRideAttributeCreation = (onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .patch(`${API_URL}/user/joyride-attribute-create`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        console.error('there is an error in creating joyRide attribute', e);
      });
  };
};
