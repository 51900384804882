import axios from 'axios';
import { getValidToken } from './filterAction';
import { API_URL, authHeader } from '../utils/helper';

export const verifyOTPwithOTPId = (otpId, otp, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(
        `${API_URL}/points/otp-verification-pastpapers?otpId=${otpId}&otp=${otp}`,
        {},
        authHeader(token)
      )
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        onSuccess('error');
        console.error('when you enter the mailed OTP', e);
      });
  };
};
export const verifyOTPWithMobile = (otp, response, onSuccess = () => {}) => {
  return async () => {
    const token = await getValidToken();
    axios
      .post(`${API_URL}/payment/otp?otp=${otp}&paymentId=${response}`, {}, authHeader(token))
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((e) => {
        onSuccess('error');
        console.error('when you enter the failed OTP', e);
      });
  };
};
