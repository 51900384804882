import { FETCH_FAQ } from '../utils/type';

const initialState = {
  faqDetail: []
};
const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAQ:
      return {
        ...state,
        faqDetail: action.data
      };
    default:
      return state;
  }
};

export default faqReducer;
