import React, { useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    width: '100%',
    maxWidth: '120px',
    justifyContent: 'center'
  },
  listItemText: {
    display: 'flex',
    padding: '5px',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center'
  },
  answerIndicator: {
    display: 'flex',
    flexDirection: 'row',
    margin: 3,
    marginRight: 15
  }
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'ANSWERED':
      return '#59981A';
    case 'DOUBTFUL':
      return '#97dde4';
    case 'UNANSWERED':
      return 'yellow';
    default:
      return '';
  }
};

const MarkedAnswers = (props) => {
  const { answerList, goToQuestion, handleSubmit, isSubmitting, noOfQuestions } = props;
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} className="d-flex align-items-center">
        <p className={classes.answerIndicator}>
          <CheckBoxOutlineBlankIcon
            style={{ color: 'green', backgroundColor: 'green', borderRadius: '5px' }}
            className="mr-1"
          />{' '}
          {t('answered_question')}
        </p>
        <p className={classes.answerIndicator}>
          <CheckBoxOutlineBlankIcon
            style={{ color: 'yellow', backgroundColor: 'yellow', borderRadius: '5px' }}
            className="mr-1"
          />{' '}
          {t('unAnswered_question')}
        </p>
        <p className={classes.answerIndicator}>
          <CheckBoxOutlineBlankIcon
            style={{ color: '#97dde4', backgroundColor: '#97dde4', borderRadius: '5px' }}
            className="mr-1"
          />{' '}
          {t('doubtFul_question')}
        </p>
      </Grid>
      <Grid item xs={12} sm={12}>
        <List className="ex-card d-flex flex-wrap">
          {answerList.map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem
                className={classes.listItem}
                key={value}
                role={undefined}
                dense
                button
                onClick={(e) => goToQuestion(e, value)}
              >
                <ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    style={{
                      border: `2px solid ${getStatusColor(value.status)}`,
                      borderRadius: '10px',
                      backgroundColor: `${getStatusColor(value.status)}`,
                      color: 'black'
                    }}
                    id={labelId}
                    primary={`${value.questionNo}`}
                  />
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid item xs={12} sm={3} className="d-flex justify-content-center m-auto mbt-5">
        <Button
          disabled={isSubmitting || noOfQuestions != answerList.length}
          fullWidth
          onClick={handleSubmit}
          style={{ float: 'left' }}
          variant="primary"
          className="btn btn-primary px-4 text-white"
          color="primary"
        >
          {t('submit')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default MarkedAnswers;
