import React from 'react';
import MainLayout from '../../layouts/MainLayout';

function TermsService() {
  return (
    <MainLayout>
      <h1>Terms & Conditions</h1>
      <div>
        <p>&nbsp;</p>
        <h6>Acceptance of Terms</h6>
        <p>
          By accessing or using ExamAnytime.com, you acknowledge that you have read, understood, and
          agree to be bound by these terms and conditions. If you do not agree to these terms,
          please do not use our website.
        </p>
        <p>&nbsp;</p>
        <h6>Use of Services</h6>
        <ul>
          <li>
            Our website provides a platform for students to purchase and complete exam papers
            uploaded by teachers for educational purposes only.
          </li>
          <li>
            You must create an account to access certain features of the website. You agree to
            provide accurate and complete information during the registration process and to keep
            your account information updated.
          </li>
          <li>
            You are solely responsible for maintaining the confidentiality of your account
            credentials and for any activity that occurs under your account.
          </li>
          <li>You agree not to use our website for any unlawful or unauthorized purpose.</li>
        </ul>
        <p>&nbsp;</p>
        <h6>Exam Completion</h6>
        <p>
          Each exam purchased can be completed only once. Once an exam is completed, it cannot be
          retaken.
        </p>
        <ul>
          <li>
            You may review completed exams at any time for personal reference and study purposes.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h6>Past Papers</h6>
        <p>
          Past papers available on www.examanytime.com are for practice and educational purposes
          only. You may purchase and practice past papers to aid in your preparation for exams.
        </p>
        <p>&nbsp;</p>
        <h6>Payment</h6>
        <p>
          Payment for exam papers and past papers is processed through our secure payment gateway.
          By making a purchase on our website, you agree to abide by the terms and conditions of our
          payment provider.
        </p>
        <p>
          All sales are final, and refunds will only be issued in accordance with our refund policy.
        </p>
        <p>&nbsp;</p>
        <h6>Intellectual Property</h6>
        <p>
          All content on ExamAnytime.com, including exam papers, past papers, text, graphics, logos,
          images, and software, is the property of ExamAnytime.com or its licensors and is protected
          by copyright and other intellectual property laws.
        </p>
        <p>
          You may not reproduce, modify, distribute, or display any content from our website without
          prior written consent from ExamAnytime.com.
        </p>
        <p>&nbsp;</p>
        <h6>Limitation of Liability</h6>
        <p>
          ExamAnytime.com shall not be liable for any direct, indirect, incidental, special, or
          consequential damages arising out of or in any way connected with the use of our website
          or the inability to use our website, even if ExamAnytime.com has been advised of the
          possibility of such damages.
        </p>
        <p>
          In no event shall www.examanytime.com&apos;s total liability to you exceed the amount paid
          by you for the services provided on our website.
        </p>
        <p>&nbsp;</p>
        <h6>Changes to Terms</h6>
        <p>
          ExamAnytime.com reserves the right to modify or update these terms and conditions at any
          time without prior notice. Changes to the terms will be effective immediately upon
          posting.
        </p>
        <p>
          It is your responsibility to review these terms periodically to ensure compliance with the
          most current version.
        </p>
      </div>
    </MainLayout>
  );
}

export default TermsService;
