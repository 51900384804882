import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Link } from '@mui/material';
import { getArticles } from '../../actions/articleAction';
import ReadOnly from '../../components/RichTextEditor/ReadOnly';

const index = () => {
  localStorage.setItem('NavName', 'Community');
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.language).selectedLanguage;
  const [allArticles, setAllArticles] = useState([]);
  console.log(allArticles);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getArticles((res) => setAllArticles(res)));
  }, []);
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
  return (
    <MainLayout>
      <div className="pt-5 pt-md-0">
        <h1>{t('community_welcome')}</h1>
        <div>
          {allArticles.length >= 1 &&
            allArticles.map((item, index) => (
              <Card key={index} style={{ margin: '5px' }}>
                <CardContent
                  style={{
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box'
                  }}
                >
                  <ReadOnly initialValue={JSON.parse(item.article)} />
                </CardContent>
                <Link
                  href={`/community-article/${item.id}`}
                  underline="none"
                  style={{ margin: '10px' }}
                >
                  Read More
                </Link>
              </Card>
            ))}
        </div>
      </div>
    </MainLayout>
  );
};
export default index;
