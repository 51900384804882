import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import '../../assets/css/theme-style.css';
import Checkbox from '@material-ui/core/Checkbox';
import ReadOnly from '../RichTextEditor/ReadOnly';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  checked: {
    color: 'green !important'
  },
  qnumber: {
    marginLeft: 10,
    marginRight: 10
  },
  checkBox: {
    marginBottom: 0,
    marginRight: 0
  }
}));

export default function AnswerList(props) {
  const { answers, selectedAnswer, isAnswerCorrect, correctAnswer } = props;
  const classes = useStyles();
  return (
    <List dense className={classes.root}>
      {answers.map((answer, i) => {
        const labelId = `checkbox-list-secondary-label-${answer.id}`;
        const isCheckedCorrect = correctAnswer.indexOf(i + 1) !== -1;
        const isCheckedAnswer = selectedAnswer.indexOf(i + 1) !== -1;
        let background = '';
        if (isAnswerCorrect && isCheckedCorrect && isCheckedAnswer) {
          background = '#99cc99';
        } else if (!isAnswerCorrect && !isCheckedCorrect && isCheckedAnswer) {
          background = '#FFC2C7';
        } else if (!isAnswerCorrect && isCheckedCorrect && !isCheckedAnswer) {
          background = '#99cc99';
        }
        return (
          <ListItem
            key={answer.id}
            button
            style={{
              backgroundColor: background
            }}
          >
            <label className={classes.qnumber}>{i + 1}.</label>
            <ListItemText id={labelId}>
              <ReadOnly initialValue={answer.initialValue} />
            </ListItemText>
            <ListItemSecondaryAction disabled>
              <Checkbox
                classes={{
                  checked: isAnswerCorrect || isCheckedCorrect ? classes.checked : {}
                }}
                className={classes.checkBox}
                edge="end"
                checked={isCheckedAnswer || isCheckedCorrect}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
